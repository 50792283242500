import React from "react"
import PropTypes from "prop-types"

export const TaskFormBlock = (props) => {
  return (
    <div className="task-form__block">
      <div className="task-form__block--inner">
        <p className="task-form__block-content">
          {props.content}
        </p>

        {props.children}
      </div>
    </div>
  )
}

TaskFormBlock.propTypes = {
  children: PropTypes.node.isRequired,
  content: PropTypes.string.isRequired,
}

export default TaskFormBlock