import React, { Component } from "react"
import PropTypes from "prop-types"

export class DashboardButton extends Component {
  static get ICON_CAMERA() {
    return require("../../assets/images/camera.png")
  }

  static get ICON_VIDEO() {
    return require("../../assets/images/video.png")
  }

  static get ICON_MICROPHONE() {
    return require("../../assets/images/microphone.png")
  }

  static get ICON_PEN() {
    return require("../../assets/images/write.png")
  }

  render() {
    const { disabled, icon, text } = this.props

    return (
      <div className={`dashboard-btn ${disabled ? "disabled" : ""}`} onClick={disabled ? null : this.props.onClick}>
        {icon && <img src={icon} />}
        <p className="dashboard-btn__description">{text}</p>
      </div>
    )
  }
}

DashboardButton.propTypes = {
  disabled: PropTypes.bool,
  icon: PropTypes.oneOf([
    DashboardButton.ICON_CAMERA,
    DashboardButton.ICON_VIDEO,
    DashboardButton.ICON_MICROPHONE,
    DashboardButton.ICON_PEN,
  ]),
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
}

DashboardButton.defaultPops = {
  disabled: false,
  icon: null,
}

export default DashboardButton
