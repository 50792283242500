import axios from "axios"

class Api {
  constructor() {
    // TODO: Make env var
    this.baseUrl = "https://mr9w5d7id8.execute-api.us-east-1.amazonaws.com/latest"
    // this.baseUrl = "http://localhost:3000"
    this.basePath = ""
    this.baseHeaders = {
      "Accept": "application/json",
      "Content-Type": "application/json",
    }

    this.baseConfig = {
      baseURL: this.baseUrl + this.basePath,
      timeout: 30000,
      headers: this.baseHeaders,
      validateStatus: (status) => {
        return ((status >= 200 && status < 300) || status === 404 || status === 409 || status === 500)
      },
    }

    this.utility = axios.create(this.baseConfig)
  }

  urlFor(path) {
    return `${this.baseUrl}${this.basePath}${path}`
  }

  setNoAuthUtility() {
    this.utility.defaults.headers.common = this.baseConfig
  }

  setAuthUtility(token) {
    this.utility.defaults.headers.common["x-auth-token"] = token
  }

  isSuccess(response) {
    return response.status >= 200 && response.status < 300
  }
}

const api = new Api()

export default api
