import React from "react"

const ReviewFormBlock = props => {
  return (
    <div className="review-new__form-block">
      <div className="container">
        <div className="row">
          {props.children}
        </div>
      </div>
    </div>
  )
}

export default ReviewFormBlock

