import React, { Component } from "react"

import { navigationPaths } from "../../../routes"

import SuccessDisplay from "../../Shared/Components/SuccessDisplay"
import ViewContainer from "../../../Layouts/ViewContainer"


class ReviewSuccess extends Component {

  render() {
    return (
      <ViewContainer>
        <SuccessDisplay
          headingText="Your review has been shared!"
          navigationPath={navigationPaths.dashboard()}
        />
      </ViewContainer>
    )
  }

} // class ReviewSuccess


export default ReviewSuccess
