import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"


class Alert extends Component {

  className() {
    const { type } = this.props

    if (type === "ERROR") {
      return "alert__container--error"
    } else if (type === "SUCCESS") {
      return "alert__container--success"
    } else {
      return "alert__container"
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.message === "" && this.props.message !== "") {
      setTimeout(() => {
        this.props.dispatch({ type: "CLEAR_ALERT" })
      }, 5000)
    }
  }

  isVisible() {
    const { message } = this.props
    return message !== ""
  }

  onClose = () => {
    this.props.dispatch({
      type: "CLEAR_ALERT"
    })
  }

  renderIcon() {
    const { type } = this.props

    if (type === "ERROR") {
      return (
        <img
          alt=""
          className="alert__icon"
          src={require("../../assets/images/error.png")}
        />
      )
    } else if (type === "SUCCESS") {
      return (
        <img
          alt=""
          className="alert__icon"
          src={require("../../assets/images/white-check.png")}
        />
      )
    } else {
      return null
    }
  }

  render() {
    const { message } = this.props

    if (this.isVisible()) {
      return (
        <div className="alert__wrapper">
          <div className={`container ${this.className()}`}>
            <div className="row">
              <div className="col-2 alert__icon_column">
                {this.renderIcon()}
              </div>

              <div className="col-8 alert__message_column">
                {message}
              </div>

              <div className="col-2 alert__button_column">
                <img
                  alt=""
                  className="alert__button"
                  onClick={this.onClose}
                  src={require("../../assets/images/x-white.png")}
                />
              </div>
            </div>
          </div>
        </div>
      )
    } else {
      return null
    }
  }

} // class Alert


const mapStateToProps = (state) => {
  return {
    message: state.alert.message,
    type: state.alert.type
  }
}

export default connect(mapStateToProps)(Alert)
