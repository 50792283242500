const initialState = {
  current: {},
  list: []
}

export default function reducer(state = initialState, action = {}) {
  const { payload, type } = action

  switch (type) {
  case "STUDIES_LOAD_SUCCEEDED":
    return {
      ...state,
      list: payload.studies,
    }

  case "STUDY_LOAD_SUCCEEDED":
    return {
      ...state,
      current: payload.study,
    }

  default:
    return state
  }
}
