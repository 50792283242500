import React, { Fragment } from "react"
import { navigationPaths, routerPaths } from "../routes"

import { Link } from "react-router-dom"
import NavLink from "./NavLink"
import NotificationIcon from "../Elements/NotificationIcon/NotificationIcon"

const NavBar = props => {
  const links = [
    <NavLink
      name="Review"
      route={routerPaths.dashboard}
    />,

    <NavLink
      name="Tasks"
      route={routerPaths.tasks}
    />,

    <NavLink
      name="Studies"
      route={routerPaths.studies}
    />,

    <NavLink
      name="Profile"
      route={routerPaths.usersProfile}
    />,
  ]

  return (
    <div className="hoot-nav">
      <div className="row">
        <div className="col-md-4">
          <Link to={{ pathname: navigationPaths.dashboard() }}>
            <img alt="hoot-logo" src={require("../assets/images/wordmark.png")} />
          </Link>
        </div>

        <div className="col-md-8">
          <div className="row hoot-nav--inner-row">
            {links.map((link, index) => {
              return (
                <Fragment key={index}>
                  {link}
                </Fragment>
              )
            })}

            <Link to={{pathname: routerPaths.notifications}}>
              <NotificationIcon />
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}


export default NavBar
