import React, { Component } from "react"
import { Provider } from "react-redux"

import ApplicationRouter from "./ApplicationRouter"

import Auth from "@aws-amplify/auth"
import { I18n } from "aws-amplify"
import {
  ConfirmSignUp,
  SignIn,
  withAuthenticator
} from "aws-amplify-react"

import SignUp from "./SignUp"
import store from "./actions/configureStore"
import { colors, fonts } from "./constants/styles"

import awsconfig from "./aws-exports"
import "./assets/stylesheets/app.scss"


Auth.configure(awsconfig)

class App extends Component {
  constructor(props) {
    super(props)

    this.state = {
      caughtError: false,
    }
  }

  componentDidCatch(error, info) {
    this.setState({ caughtError: true })
  }

  render() {
    return (
      <Provider store={store}>
        <ApplicationRouter error={this.state.caughtError} {...this.props} />
      </Provider>
    )
  }
}

const authScreenLabels = {
  en: {
    "Sign in to your account": "Log in to your account",
  }
}

export const HootTheme = {
  selectInput: {
    height: "100%",
    padding: 0,
  },

  formSection: {
    minWidth: "0",
  },

  button: {
    backgroundColor: colors.brandBlue,
    borderRadius: "24.5px",
    display: "block",
    letterSpacing: "0.8px",
    width: "100%",
    ...fonts.roboto.medium,
  },

  sectionFooter: {
    flexDirection: "column",
    textAlign: "center",
  },

  sectionFooterPrimaryContent: {
    display: "block",
    margin: "0 auto",
    width: "60%",
  },

  sectionFooterSecondaryContent: {
    color: colors.black,
    display: "block",
    letterSpacing: "0.76px",
    margin: "0 auto",
    marginTop: "20px",
    ...fonts.roboto.regular,
  },

  a: {
    color: colors.brandBlue,
  },

  sectionHeader: {
    color: colors.brandBlue,
    fontSize: "20px",
    letterSpacing: "0.96px",
    ...fonts.roboto.medium,
  },

  input: {
    border: "1px solid rgba(139, 145, 163, 0.25)",
    borderRadius: "3px",
    height: "30px",
  },

  formField: {
    color: colors.darkGrey,
    letterSpacing: "1.07px",
    ...fonts.roboto.medium,
  },
}

I18n.setLanguage("en")
I18n.putVocabularies(authScreenLabels)

export default withAuthenticator(App, false, [
  <SignIn />,
  <SignUp />
], null, HootTheme)
