import React, { Component } from "react"
import PropTypes from "prop-types"


class AudioButton extends Component {

  className = () => {
    const { isDisabled } = this.props

    let classNameString = "audio_button"

    if (isDisabled) {
      classNameString += " audio_buton__disabled"
    }

    return classNameString
  }

  handleClick = () => {
    const { isDisabled, onClick } = this.props

    if (!isDisabled) {
      onClick()
    }
  }

  render() {
    const { src } = this.props

    return (
      <div
        className={this.className()}
        onClick={this.handleClick}
      >
        <img
          alt=""
          className="audio_button__icon"
          src={src}
        />
      </div>
    )
  }

} // class AudioButton

AudioButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  src: PropTypes.string.isRequired,

  isDisabled: PropTypes.bool
}

AudioButton.defaultProps = {
  isDisabled: false
}

export default AudioButton
