import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import PrimaryButton from "../../../Elements/Button/PrimaryButton"
import TextInput from "../../../Elements/TextInput/TextInput"


class StudyEnrollmentForm extends Component {

  constructor(props) {
    super(props)

    this.state = {
      formData: {
        studyCode: ""
      },
      isSubmitting: false
    }
  }

  allowSubmit() {
    const { formData, isSubmitting } = this.state

    return formData.studyCode !== "" && !isSubmitting
  }

  onInputChange = (fieldName, fieldValue) => {
    this.setState({
      formData: {
        ...this.state.formData,
        [fieldName]: fieldValue
      }
    })
  }

  onSubmit = () => {
    const { onSetLoading } = this.props
    const { formData } = this.state

    onSetLoading()

    this.setState({
      isSubmitting: true
    }, () => {
      this.props.dispatch({
        type: "STUDY_ENROLL_SAGA",
        payload: {
          studyCode: formData.studyCode
        },
        callback: this.resetForm
      })
    })
  }

  resetForm = () => {
    const { onUnsetLoading } = this.props

    this.setState({
      formData: {
        studyCode: ""
      },
      isSubmitting: false
    }, () => {
      onUnsetLoading()
    })
  }

  render() {
    const { formData } = this.state

    return (
      <div className="container study_enrollment_form__container">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-4">
            <div className="row">
              <div className="col-sm-12">
                <h1>Invited to a study?</h1>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12">
                <h4>Enter your code here</h4>
              </div>
            </div>
          </div>

          <div className="col-sm-12 col-md-6 col-lg-5 study_enrollment_form__input_column">
            <TextInput
              className="study_enrollment_form__input"
              isDisabled={this.isSubmitting}
              name="studyCode"
              onChange={this.onInputChange}
              placeholder="Enter Code*"
              value={formData.studyCode}
            />
          </div>

          <div className="col-sm-12 col-md-6 col-lg-3 study_enrollment_form__button_column">
            <PrimaryButton
              className={"study_enrollment_form__button"}
              isDisabled={!this.allowSubmit()}
              onClick={this.onSubmit}
              text="Join Study"
            />
          </div>
        </div>
      </div>
    )
  }

} // class StudyEnrollmentForm


StudyEnrollmentForm.propTypes = {
  onSetLoading: PropTypes.func,
  onUnsetLoading: PropTypes.func
}

StudyEnrollmentForm.defaultProps = {
  onSetLoading: () => {},
  onUnsetLoading: () => {}
}

export default connect()(StudyEnrollmentForm)
