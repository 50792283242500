import React, { Component } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"

import moment from "moment"

import DeleteIcon from "./DeleteIcon"

export class NotificationRow extends Component {
  constructor(props) {
    super(props)

    this.setType()

    this.state = {
      isDeleting: false,
    }
  }

  static get TYPE_STUDY() {
    return "study"
  }

  static get TYPE_TASK() {
    return "task"
  }

  static get TYPE_MESSAGE() {
    return "message"
  }

  setType = () => {
    const { notification } = this.props

    let type

    if (notification.taskId) type = "task"
    if (notification.studyId) type = "study"
    if (notification.from) type = "message"

    this.type = type
  }

  renderIcon() {
    const { notification } = this.props

    let type

    if (notification.taskId) type = "task"
    if (notification.studyId) type = "study"
    if (notification.from) type = "message"

    switch (type) {
    case "task":
      return <img alt="notification-icon" src={require("../../../assets/images/tasksBlue.png")} />
    case "study":
      return <img alt="notification-icon" src={require("../../../assets/images/studyBlue.png")} />
    case "message":
      return <img alt="notification-icon" src={require("../../../assets/images/tasksBlue.png")} />
    default:
      return null
    }
  }

  handleClick = () => {
    this.props.onClick(this.props.notification, this.type)
  }

  render() {
    const { notification } = this.props

    return (
      <div className="row notification-row">
        <div className="col-2">
          {this.renderIcon()}
        </div>

        <div className="col-8 notification-row__content">
          <a href="javascript:void(0)" onClick={this.handleClick}>
            <p className="notification-row__subject">
              {notification.subject}
            </p>
          </a>

          <p className="notification-row__sub-header">
            {`${moment(notification.createdAt).fromNow()} | ${notification.fromName}`}
          </p>
        </div>

        <div className="col-2 notification-row__delete-container">
          <DeleteIcon
            onClick={() => { this.props.onDelete(this.props.notification) }}
            loading={this.props.isLoadingDelete}
          />
        </div>
      </div>
    )
  }
}

export const notificationPropTypes = PropTypes.shape({
  id: PropTypes.number.isRequired,
  subject: PropTypes.string.isRequired,
  createdDate: PropTypes.string.isRequired,
  fromName: PropTypes.string.isRequired,
})

NotificationRow.propTypes = {
  notification: notificationPropTypes,
  onClick: PropTypes.func.isRequired,
}

export default connect()(NotificationRow)
