import { call, takeEvery } from "redux-saga/effects"

import { Auth } from "aws-amplify"

import api from "../../utils/api"

// Call this function when you need to set the api to use auth headers from
export function* setAuthApi() {
  try {
    const session = yield call([Auth, Auth.currentSession])
    const token = session.accessToken.jwtToken

    yield call([api, api.setAuthUtility], token)

    return true
  } catch {
    return false
  }
}

export default function* authSaga() {
  yield takeEvery("SET_AUTH_API_SAGA", setAuthApi)
}
