import React, { Component } from "react"
import PropTypes from "prop-types"


class StudyListItem extends Component {

  render() {
    const { onNavigate, study } = this.props

    return (
      <div
        className="container study_list_item__container"
        onClick={() => onNavigate(study.studyId)}
      >
        <div className="row">
          <div className="col-sm-12">
            <img
              alt=""
              className="study_list_item__image"
              src={study.imageUri}
            />
            <img
              alt=""
              className="study_list_item__image_mobile"
              src={study.mobileImageUri}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12">
            <div className="study_list_item__content">

              <div className="row">
                <div className="col-sm-12">
                  <h3>{study.name}</h3>
                  <h4>Click to enter study</h4>
                </div>
              </div>

              {/*
                <div className="row">
                  <div className="col-sm-12">
                    TODO: study short desc.
                  </div>
                </div>
              */}
            </div>
          </div>
        </div>
      </div>
    )
  }

} // class StudyListItem


StudyListItem.propTypes = {
  onNavigate: PropTypes.func.isRequired,
  study: PropTypes.object.isRequired
}

export default StudyListItem
