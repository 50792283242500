export function getCategoriesWithSelectedStatus(state) {
  const allCategories = state.category.all
  const userCategories = state.category.userCategories

  return allCategories.map(category => {
    // NOTE: Purposeful == because category.id is a number & categoryId is a string
    const isChecked = userCategories.find(uc => uc.categoryId == category.id) !== undefined

    return {
      ...category,
      isChecked,
    }
  })
}
