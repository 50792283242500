import React from "react"
import PropTypes from "prop-types"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons"

import Spinner from "../../../Elements/Loading/Spinner"

const DeleteIcon = props => {
  const { loading, onClick } = props

  if (loading) {
    return <Spinner />
  } else {
    return (
      <FontAwesomeIcon
        className="notification-row__delete"
        icon={faTrashAlt}
        onClick={onClick}
      />
    )
  }
}

DeleteIcon.propTypes = {
  loading: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
}

DeleteIcon.defaultProps = {
  loading: false,
}

export default DeleteIcon