const initialState = {
  all: [],
  userCategories: [],
}

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action

  switch (type) {
  case "GET_ALL_CATEGORIES_SUCCEEDED":
    return {
      ...state,
      all: payload
    }
  case "GET_USER_CATEGORIES_SUCCEEDED":
    return {
      ...state,
      userCategories: payload,
    }
  case "ADD_USER_CATEGORY":
    return {
      ...state,
      userCategories: [...state.userCategories, payload]
    }
  case "REMOVE_USER_CATEGORY":
    return {
      ...state,
      userCategories: state.userCategories.filter(c => c.categoryId != payload.id)
    }
  default:
    return state
  }
}
