import { call, put, takeEvery } from "redux-saga/effects"

import { Auth } from "aws-amplify"

import api from "../../utils/api"

export function* getProfile(action) {
  try {
    yield put({ type: "GET_PROFILE_REQUESTED" })

    const session = yield call([Auth, Auth.currentSession])

    const token = session.accessToken.jwtToken

    const user = yield call([Auth, Auth.currentAuthenticatedUser])

    yield call([api, api.setAuthUtility], token)

    const response = yield call([api, api.utility.get], api.urlFor("/profile"))

    if (response.status == 404) {
      /** Profile has not been created yet **/
      yield call(createProfile)
    } else if (response.status == 200) {
      if (action.onSuccess) {
        yield call(action.onSuccess, response.data)
      }

      yield put({ type: "GET_PROFILE_SUCCEEDED" })
      /** User already has profile**/
      // const profile = new Profile(response.data)
      // yield put({ type: "GET_PROFILE_SUCCEEDED", payload: profile })
    }
  } catch (e) {
    yield put({ type: "GET_PROFILE_FAILED", payload: e })
  }
}

export function* createProfile() {
  try {
    yield put({ type: "CREATE_PROFILE_REQUESTED" })

    const user = yield call([Auth, Auth.currentAuthenticatedUser])

    if (user.attributes) {
      const response = yield call([api, api.utility], {
        method: "post",
        url: api.urlFor("/profile"),
        data: { email: user.attributes.email },
      })

      if (response.status == 200) {
        // TODO: Handle when profile is created for the first time.
        yield put({ type: "CREATE_PROFILE_SUCCEEDED" })
      } else {
        yield put({ type: "CREATE_PROFILE_FAILED", payload: response })
      }
    }
  } catch (e) {
    yield put({
      type: "CREATE_PROFILE_FAILED",
      payload: e.message,
    })
  }
}

export function* updateProfile(action) {
  try {
    const session = yield call([Auth, Auth.currentSession])

    const token = session.accessToken.jwtToken

    const user = yield call([Auth, Auth.currentAuthenticatedUser])

    yield call([api, api.setAuthUtility], token)
    yield put({ type: "UPDATE_PROFILE_REQUESTED" })

    const body = action.payload

    const response = yield call([api, api.utility], {
      method: "put",
      url: api.urlFor("/profile"),
      data: body,
    })

    if (api.isSuccess(response)) {
      if (action.onSuccess) {
        yield call(action.onSuccess, response)
      }

      yield put({ type: "UPDATE_PROFILE_SUCCEEDED", payload: body })
    } else {
      yield put({
        type: "UPDATE_PROFILE_FAILED",
        payload: response.data,
      })
    }
  } catch (e) {
    yield put({
      type: "UPDATE_PROFILE_FAILED",
      payload: e.message,
    })
  }
}

export default function* profileSaga() {
  yield takeEvery("GET_PROFILE_SAGA", getProfile)
  yield takeEvery("UPDATE_PROFILE_SAGA", updateProfile)
}
