import React, { Component } from "react"
import AppModal from "./AppModal"

function withModal(WrappedComponent) {
  return class extends Component {
    render() {
      const { buttonText, isOpen, onClose, renderButton } = this.props

      return (
        <AppModal
          isOpen={isOpen}
          onClose={onClose}
          buttonText={buttonText}
          renderButton={renderButton}
        >
          <WrappedComponent {...this.props} />
        </AppModal>
      )
    }
  }
}

export default withModal
