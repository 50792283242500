import React, { Component } from "react"
import Auth from "@aws-amplify/auth"
import TermsOfUseModal from "./Elements/TermsOfUseModal/TermsOfUseModal"
import { countryCodes } from "./constants/countryCodes"

class SignUp extends Component {
  constructor(props) {
    super(props)

    const sortedCountryCodes = countryCodes.map(c => c["dial_code"]).sort()

    this.countryCodes = Array.from(new Set(sortedCountryCodes))

    this.state = {
      acceptedTerms: false,
      username: "",
      email: "",
      password: "",
      phoneNumber: "",
      phoneCountryCode: "+1",
      error: {},
      termsOfUseOpen: false,
    }
  }

  signUp = () => {
    const { username, email, password, phoneCountryCode, phoneNumber } = this.state

    Auth.signUp({
      username,
      password,
      attributes: {
        email: username,
        phone_number: "+18888888888"
        // phone_number: phoneCountryCode + phoneNumber,
      }
    }).then(data => {
      this.props.onStateChange("signIn", data)
    }).catch(err => {
      if (err.message) {
        this.setState({ error: err })
      }
    })
  }

  openTermsModal = () => {
    this.setState({ termsOfUseOpen: true })
  }

  render() {
    if (this.props.authState === "signUp") {
      return (
        <div className="Section__container">
          <div className="Form__formContainer">
            <div className="Form__formSection">
              <div className="Section__sectionHeader">
                Create a new account
              </div>

              <div className="Section__sectionBody">
                <div className="Form__formField">
                  <div className="Input__inputLabel">
                    Email/Username *
                  </div>

                  <input 
                    className="Input__input"
                    type="text"
                    value={this.state.username}
                    onChange={(e) => this.setState({ username: e.target.value })}
                    placeholder="Create a username"
                  />
                </div>

                <div className="Form__formField">
                  <div className="Input__inputLabel">
                    Password *
                  </div>

                  <input
                    className="Input__input"
                    type="password"
                    value={this.state.password}
                    onChange={(e) => this.setState({ password: e.target.value })}
                    placeholder="Create a password"
                  />
                </div>
{/*

                <div className="Form__formField">
                  <div className="Input__inputLabel">
                    Email Address *
                  </div>

                  <input
                    className="Input__input"
                    type="email"
                    value={this.state.email}
                    onChange={(e) => this.setState({ email: e.target.value })}
                    placeholder="janedoe@email.com"
                  />
                </div>

                <div className="Form__formField">
                  <div className="Input__inputLabel">
                    Phone Number *
                  </div>

                  <div className="row">
                    <div className="col-md-3">
                      <select
                        className="form-control"
                        value={this.state.phoneCountryCode}
                        onChange={(e) => this.setState({ phoneCountryCode: e.target.value })}
                      >
                        {this.countryCodes.map(countryCode => {
                          return (
                            <option value={countryCode}>
                              {countryCode}
                            </option>
                          )
                        })}
                      </select>
                    </div>

                    <div className="col-md-9">
                      <input
                        className="Input__input"
                        type="text"
                        value={this.state.phoneNumber}
                        onChange={(e) => this.setState({ phoneNumber: e.target.value })}
                        placeholder="555-555-1212"
                      />
                    </div>
                  </div>
                </div>
*/}

                <span className="Section__sectionFooterSecondaryContent">
                  <input
                    id="acceptTerms"
                    name="acceptTerms"
                    type="checkbox"
                    checked={this.state.acceptedTerms}
                    onChange={() => this.setState({ acceptedTerms: !this.state.acceptedTerms })}
                  />

                  <label className="Label__label" htmlFor="acceptTerms">
                    I agree to the
                    <a
                      href="javascript:void(0)"
                      className="btn btn-link Button__termsBtn"
                      onClick={this.openTermsModal}
                    >
                      Terms & Conditions
                    </a>
                  </label>
                </span>

                <span className="Section__sectionFooterPrimaryContent">
                  <button
                    type="button"
                    value="Sign Up"
                    onClick={this.signUp}
                    className="Button__button"
                    disabled={!this.state.acceptedTerms}
                  >
                    CREATE ACCOUNT
                  </button>
                </span>
              </div>

              <p>
                {this.state.error.message && <p>{this.state.error.message}</p>}
              </p>

              <TermsOfUseModal
                isOpen={this.state.termsOfUseOpen}
                onClose={() => this.setState({ termsOfUseOpen: false })}
              />
            </div>
          </div>
        </div>
      )
    }

    return null
  }
}

export default SignUp