import React, { Component } from "react"
import Loading from "./Loading"

function withLoadingState(WrappedComponent) {
  return class extends Component {
    render() {
      if (this.props.loading) {
        return <Loading />
      } else {
        return <WrappedComponent {...this.props} />
      }
    }
  }
}

export default withLoadingState
