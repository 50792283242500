import { matchPath } from "react-router"

// TODO: Move these to route/nav helpers
export const matchConfig = (path) => {
  return {
    path: path,
    exact: true,
    strict: false,
  }
}

export const isActive = (path) => {
  return !!matchPath(window.location.pathname, matchConfig(path))
}

export const getAsset = (path, base) => {
  if (isActive(path)) {
    return require(`../assets/images/${base}Blue.png`)
  } else {
    return require(`../assets/images/${base}Drk.png`)
  }
}

