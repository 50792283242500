import React from "react"
import { Link } from "react-router-dom"

import { navigationPaths } from "../routes"

const MobileTopNav = () => {
  return (
    <div className="mobile-top-nav">
      <Link to={{ pathname: navigationPaths.dashboard() }}>
        <img alt="hoot-log" src={require("../assets/images/wordmark.png")} />
      </Link>
    </div>
  )
}

export default MobileTopNav