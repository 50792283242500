import { call, put, takeEvery } from "redux-saga/effects"
import api from "../../utils/api"

import { setAuthApi } from "./authSaga"

function* getNotifications(action) {
  try {
    yield call(setAuthApi)
    yield put({ type: "GET_NOTIFICATIONS_REQUESTED" })

    const response = yield call([api, api.utility.get], api.urlFor("/notifications"))

    if (response.status === 200) {
      yield put({ type: "GET_NOTIFICATIONS_SUCCEEDED", payload: response.data })
    }
  } catch (e) {
    yield put({
      type: "GET_NOTIFICATIONS_FAILED",
      payload: { message: "Failed to fetch notifications" }
    })

    yield put({
      type: "SET_ALERT",
      payload: {
        type: "ERROR",
        message: "Failed to fetch notifications",
      }
    })
  } finally {
    if (action.onComplete) {
      yield call(action.onComplete)
    }
  }
}

export function* removeNotification(action) {
  try {
    yield put({ type: "REMOVE_NOTIFICATION_REQUESTED" })
    const response = yield call([api, api.utility.get], api.urlFor(`/notification/${action.payload.notificationId}`))

    if (response.status === 200) {
      yield put({
        type: "REMOVE_NOTIFICATION_SUCCEEDED",
        payload: {
          notificationId: action.payload.notificationId
        }
      })
    } else {
      yield put({
        type: "REMOVE_NOTIFICATION_FAILED",
        payload: response
      })
    }
  } catch (e) {
    yield put({
      type: "REMOVE_NOTIFICATION_FAILED",
      payload: e.message
    })
  } finally {
    if (action.onComplete) {
      yield call(action.onComplete)
    }
  }
}

export default function* notificationSaga() {
  yield takeEvery("GET_NOTIFICATIONS_SAGA", getNotifications)
  yield takeEvery("REMOVE_NOTIFICATION_SAGA", removeNotification)
}
