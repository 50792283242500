import React, { Component } from "react"
import Auth from "@aws-amplify/auth"

export class LogOut extends Component {
  signOut = () => {
    Auth.signOut().then(data => {
      window.location = "/"
    })
  }

  render() {
    return (
      <a onClick={this.signOut} href="javascript:void(0)" className="sign-out">
        Log Out
      </a>
    )
  }
}

export default LogOut
