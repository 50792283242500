import React, { Component } from "react"
import PropTypes from "prop-types"

class TextInput extends Component {

  render() {
    const { className, isDisabled, name, onChange, placeholder, type, value } = this.props

    return (
      <input
        className={className}
        disabled={isDisabled}
        onChange={(event) => { onChange(name, event.target.value) }}
        placeholder={placeholder}
        type={type}
        value={value}
      />
    )
  }

} // class TextInput

TextInput.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,

  className: PropTypes.string,
  isDisabled: PropTypes.bool,
  placeholder: PropTypes.string,
  type: PropTypes.oneOf(["text", "password"]),
}

TextInput.defaultProps = {
  className: PropTypes.string,
  isDisabled: false,
  placeholder: "",
  type: "text",
}

export default TextInput
