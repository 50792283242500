import React from "react"
import PropTypes from "prop-types"

import { NavLink as RouterLink, withRouter } from "react-router-dom"


function isActive(targetPath, currentPath) {
  if (targetPath === "/") {
    return RegExp(/^\/$/).test(currentPath) || RegExp("reviews").test(currentPath)
  }
  else {
    return RegExp(targetPath).test(currentPath)
  }
}

const NavLink = props => {


  const { asset, isMobile, name, route } = props

  const currentPath = props.location.pathname

  if (isMobile) {
    return (
      <RouterLink
        exact
        activeClassName="mobile-nav--link__active"
        className="mobile-nav--link"
        isActive={() => isActive(route, currentPath)}
        to={route}
      >
        {asset && <img alt="mobile-nav-link" src={asset} />}
        <p>{name}</p>
      </RouterLink>
    )
  }
  else {
    return (
      <RouterLink
        exact
        activeClassName="nav--link__active"
        className="nav--link"
        isActive={() => isActive(route, currentPath)}
        to={route}
      >
        <div className="col-md-4">{name}</div>
      </RouterLink>
    )
  }
}

NavLink.propTypes = {
  asset: PropTypes.any,
  name: PropTypes.string.isRequired,
  route: PropTypes.string.isRequired,
  isMobile: PropTypes.bool,
}

NavLink.defaultProps = {
  asset: null,
  isMobile: false,
}

export default withRouter(NavLink)
