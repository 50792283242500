import React, { Component, Fragment } from "react"
import PropTypes from "prop-types"
import Loading from "../../../Elements/Loading/Loading"

import ReviewFormBlock from "./ReviewFormBlock"

class ReviewForm extends Component {
  renderDataPreview() {
    const { mediaData, mediaType } = this.props.formData

    if (mediaType === "IMAGE") {
      return (
        <div className="col-sm-12 col-md-6">
          <img alt="media-preview" className="media-preview" src={mediaData.data} />
        </div>
      )
    }

    if (mediaType === "AUDIO") {
      return (
        <div className="col-sm-12 col-md-6">
          <p>{`${mediaData.startTime}.wav`}</p>
        </div>
      )
    }

    if (mediaType !== "TEXT") {
      return (
        <div className="col-sm-12 col-md-6">
          <p>{mediaData.name}</p>
        </div>
      )
    }
  }

  handleSubmit = () => {
    if (this.props.disabled) {
      return
    }

    this.props.onSubmit()
  }

  render() {
    const { disabled, formData, onFieldChange, submitting } = this.props

    return (
      <Fragment>
        <ReviewFormBlock>
          {this.renderDataPreview()}

          <div className={`col-sm-12 ${formData.mediaType === "TEXT" ? "" : "col-md-6"} form-block`}>
            <label className="input-label">
              Write something about your experience
            </label>

            <textarea
              onChange={(e) => { onFieldChange("comment", e.target.value) }}
              type="text"
              value={formData.comment}
            />
          </div>
        </ReviewFormBlock>

        <ReviewFormBlock>
          <div className="col-sm-12 col-md-6">
            <h3 className="form-block-title">
              Details
            </h3>
          </div>

          <div className="col-sm-12 col-md-6">
            <div className="form-block">
              <label className="input-label required">
                Company
              </label>

              <input
                onChange={(e) => { onFieldChange("company", e.target.value) }}
                type="text"
                value={formData.company}
              />
            </div>

            <div className="form-block">
              <label className="input-label required">
                Product or Service Name
              </label>

              <input
                onChange={(e) => { onFieldChange("productName", e.target.value) }}
                type="text"
                value={formData.productName}
              />
            </div>

            { /* NOTE: Removed per client request for now, will potentially be re-added later - 12/13/18 */ }
            { /*<div className="form-block">
              <label className="input-label required">
                Category*
              </label>

              <input
                onChange={(e) => { onFieldChange("category", e.target.value) }}
                type="text"
                value={formData.category}
              />
            </div> */}

            { /* NOTE: Removed per client request for now, will potentially be re-added later - 12/13/18 */ }
            { /* <div className="form-block">
              <label className="input-label">
                Location
              </label>

              <input
                onChange={(e) => { onFieldChange("location", e.target.value) }}
                type="text"
                value={formData.location}
              />
            </div> */}
          </div>
        </ReviewFormBlock>

        <ReviewFormBlock>
          <div className="col-sm-12 col-md-6">
            <h3 className="form-block-title">
              Share
            </h3>
          </div>

          <div className="col-sm-12 col-md-6">
            <div className="row">
              <label htmlFor="share-check" className="input-label share-check-label">
                Share to Company?
              </label>

              { /* TODO: Make slider its own component */}
              <label className="switch">
                <input
                  type="checkbox"
                  checked={formData.share}
                  onChange={() => { onFieldChange("share", !formData.share) }}
                />
                <span className="slider round"></span>
              </label>
            </div>
          </div>
        </ReviewFormBlock>

        <ReviewFormBlock>
          <button
            className={`btn primary-app-btn review-submit-btn ${disabled ? "disabled" : ""}`}
            onClick={this.handleSubmit}
          >
            {submitting ? <Loading size="small" /> : "Submit"}
          </button>
        </ReviewFormBlock>
      </Fragment>
    )
  }

}

ReviewForm.propTypes = {
  disabled: PropTypes.bool,
  formData: PropTypes.shape({
    category: PropTypes.string.isRequired,
    comment: PropTypes.string.isRequired,
    company: PropTypes.string.isRequired,
    location: PropTypes.string.isRequired,
    mediaData: PropTypes.object.isRequired,
    mediaType: PropTypes.string.isRequired,
    productName: PropTypes.string.isREquired,
    share: PropTypes.bool.isRequired,
  }).isRequired,

  onFieldChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
}

ReviewForm.defaultProps = {
  disabled: false,
  submitting: false,
}

export default ReviewForm
