import authSaga from "./sagas/authSaga"
import categorySaga from "./sagas/categorySaga"
import profileSaga from "./sagas/profileSaga"
import studySaga from "./sagas/studySaga"
import submissionSaga from "./sagas/submissionSaga"
import taskSaga from "./sagas/taskSaga"
import passwordSaga from "./sagas/passwordSaga"
import notificationSaga from "./sagas/notificationSaga"

export default function* rootSaga() {
  yield [
    authSaga(),
    categorySaga(),
    profileSaga(),
    studySaga(),
    submissionSaga(),
    taskSaga(),
    passwordSaga(),
    notificationSaga(),
  ]
}
