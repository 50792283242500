export const GENDERS = [
  {
    label: "Female",
    value: "female"
  },
  {
    label: "Male",
    value: "male"
  },
  {
    label: "Non-Binary",
    value: "non-binary"
  },
  {
    label: "Prefer not to specify",
    value: "prefer not to specify"
  },
]
