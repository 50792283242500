import React, { Component } from "react"
import PropTypes from "prop-types"

import CheckBoxList from "../../../Elements/CheckBoxList/CheckBoxList"

const TaskTextQuestion = props => {
  const { onChange, question } = props

  const handleChange = (e) => {
    onChange({
      description: e.target.value,
      questionId: question.id,
    })
  }

  return (
    <textarea
      className="task-form__text-area"
      onChange={handleChange}
      type="textarea"
      value={question.answer || ""}
    />
  )
}

const TaskRadioQuestion = props => {
  const { onChange, question } = props

  const dataForCheckboxes = question.details.map(answer => {
    return {
      ...answer,
      isChecked: question.answer === answer.description,
      name: answer.description,
    }
  })

  return (
    <CheckBoxList
      columnSize={12}
      onChange={onChange}
      options={dataForCheckboxes}
    />
  )
}

const TaskCheckboxQuestion = props => {
  const { onChange, question } = props

  const dataForCheckboxes = question.details.map(answer => {
    return {
      ...answer,
      isChecked: question.answers.map(a => a.id).includes(answer.id),
      name: answer.description,
    }
  })

  return (
    <CheckBoxList
      columnSize={12}
      onChange={onChange}
      options={dataForCheckboxes}
    />
  )
}

class TaskQuestion extends Component {
  static get QUESTION_TYPE_TEXT() {
    return "TEXT"
  }

  static get QUESTION_TYPE_RADIO() {
    return "RADIO"
  }

  static get QUESTION_TYPE_CHECKBOX() {
    return "CHECKBOX"
  }

  render() {
    const { question, onChange } = this.props

    switch (question.questionType) {
    case TaskQuestion.QUESTION_TYPE_TEXT:
      return <TaskTextQuestion onChange={onChange} question={question} />
    case TaskQuestion.QUESTION_TYPE_RADIO:
      return <TaskRadioQuestion onChange={onChange} question={question} />
    case TaskQuestion.QUESTION_TYPE_CHECKBOX:
      return <TaskCheckboxQuestion onChange={onChange} question={question} />
    default:
      return null
    }
  }
}

export const taskQuestionPropTypes = {
  question: PropTypes.shape({
    id: PropTypes.number.isRequired,
    details: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      questionId: PropTypes.number.isRequired,
      description: PropTypes.string.isRequired,
    })).isRequired,
    name: PropTypes.string.isRequired,
    questionType: PropTypes.oneOf([
      TaskQuestion.QUESTION_TYPE_TEXT,
      TaskQuestion.QUESTION_TYPE_RADIO,
      TaskQuestion.QUESTION_TYPE_CHECKBOX,
    ]),
  }),

  onChange: PropTypes.func.isRequired,
}

TaskQuestion.propTypes = taskQuestionPropTypes

export default TaskQuestion