import React from "react"
import PropTypes from "prop-types"

import NotificationRow, { notificationPropTypes } from "./NotificationRow"
import withLoadingState from "../../../Elements/Loading/withLoadingState"

const NotificationsList = props => {
  if (props.notifications.length === 0) {
    return <h1 className="notifications-empty">You're all caught up!</h1>
  }

  return props.notifications.map((notification, index) => {
    return (
      <div className="container" key={index}>
        <NotificationRow
          notification={notification}
          onClick={props.onNotificationClick}
          onDelete={props.onNotificationDelete}
          isLoadingDelete={notification.id == props.deletingNotification.id}
        />
      </div>
    )
  })
}

NotificationsList.propTypes = {
  loading: PropTypes.bool,
  notifications: PropTypes.arrayOf(notificationPropTypes).isRequired,
  onNotificationClick: PropTypes.func.isRequired,
  onNotificationDelete: PropTypes.func.isRequired,
  deletingNotification: PropTypes.object,
}

NotificationsList.defaultProps = {
  loading: false,
  deletingNotification: {},
}

export default withLoadingState(NotificationsList)
