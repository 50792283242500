import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import { navigationPaths } from "../../../routes"

import StudyShowContent from "../Components/StudyShowContent"
import ViewContainer from "../../../Layouts/ViewContainer"

class StudyShow extends Component {
  constructor(props) {
    super(props)

    this.state = {
      studyId: this.props.match.params.id,
      fetching: false,
    }
  }

  componentDidMount() {
    this.setState({ fetching: true })

    this.props.dispatch({
      type: "STUDY_LOAD_SAGA",
      payload: {
        studyId: this.state.studyId,
      },

      onComplete: () => {
        this.setState({ fetching: false })
      }
    })
  }

  navigateToTasks = () => {
    this.props.history.push({
      pathname: navigationPaths.tasks(),
    })
  }

  render() {
    const { study } = this.props

    return (
      <ViewContainer>
        <StudyShowContent
          loading={this.state.fetching}
          onNavigate={this.navigateToTasks}
          study={study}
        />
      </ViewContainer>
    )
  }
} // class StudyShow


StudyShow.propTypes = {
  study: PropTypes.object.isRequired
}

const mapStateToProps = (state) => {
  return {
    study: state.study.current,
  }
}

export default connect(mapStateToProps)(StudyShow)
