import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import { STATES } from "../../../constants/states"
import { GENDERS } from "../../../constants/genders"

import AvatarField from "./AvatarField"
import DatePicker from "../../../Elements/DatePicker/DatePicker"
import LogOut from "../../../Elements/LogOut/LogOut"
import withLoadingState from "../../../Elements/Loading/withLoadingState"

export class ProfileAbout extends Component {
  handleFieldChange = (fieldName, val) => {
    this.props.onFieldChange("userInfo", fieldName, val)
  }

  render() {
    const { formData, onFieldChange, onSubmit } = this.props

    return (
      <div>
        <div className="form-section">
          <div className="row tab-outer-form-block">
            <div className="col-md-6 col-sm-12">
              <AvatarField
                avatarUri={formData.userInfo.mediaUri}
              />
            </div>

            <div className="col-md-6 col-sm-12">
              <div className="form-block">
                <label className="input-label">
                  First Name
                </label>

                <input
                  type="text"
                  value={formData.userInfo.firstName}
                  onChange={(e) => { onFieldChange("userInfo", "firstName", e.target.value) }}
                />
              </div>

              <div className="form-block">
                <label className="input-label">
                  Last Name
                </label>

                <input
                  type="text"
                  value={formData.userInfo.lastName}
                  onChange={(e) => { onFieldChange("userInfo", "lastName", e.target.value) }}
                />
              </div>

              <div className="form-block">
                <label className="input-label">
                  Email Address
                </label>

                <input
                  type="text"
                  value={formData.userInfo.email}
                  onChange={(e) => { onFieldChange("userInfo", "email", e.target.value) }}
                />
              </div>

              <button
                className="btn primary-app-btn"
                onClick={() => { onSubmit("userInfo") }}
              >
                Save
              </button>
            </div>
          </div>
        </div>

        <div className="form-section">
          <div className="row tab-outer-form-block">
            <div className="col-md-6 col-sm-12">
              <h1>
                Bio
              </h1>
            </div>

            <div className="col-md-6 col-sm-12">
              <div className="form-block">
                <label className="input-label">
                  City
                </label>

                <input
                  type="text"
                  value={formData.bio.city}
                  onChange={(e) => { onFieldChange("bio", "city", e.target.value) }}
                />
              </div>

              <div className="form-block">
                <label className="input-label">
                  State
                </label>

                <select value={formData.bio.state} onChange={(e) => { onFieldChange("bio", "state", e.target.value) }}>
                  {Object.keys({ ...{ "": null }, ...STATES }).map((stateKey, index) => {
                    return (
                      <option key={index} value={stateKey}>
                        {STATES[stateKey]}
                      </option>
                    )
                  })}
                </select>
              </div>

              <div className="form-block">
                <label className="input-label">
                  Birthday
                </label>

                <DatePicker
                  date={formData.bio.birthday}
                  onChange={ (date) => { onFieldChange("bio", "birthday", date) }}
                />
              </div>

              <div className="form-block">
                <label className="input-label">
                  Gender
                </label>

                <select
                  value={formData.bio.gender}
                  onChange={(e) => { onFieldChange("bio", "gender", e.target.value) }}
                >
                  {[{ label: "", value: null }, ...GENDERS].map((gender, index) => {
                    return (
                      <option value={gender.value}>
                        {gender.label}
                      </option>
                    )
                  })}
                </select>
              </div>

              <div className="form-block">
                <label className="input-label">
                  Ethnicity
                </label>

                <input
                  type="text"
                  value={formData.bio.ethnicity}
                  onChange={(e) => { onFieldChange("bio", "ethnicity", e.target.value) }}
                />
              </div>

              <button
                className="btn primary-app-btn"
                onClick={() => { onSubmit("bio") }}
              >
                Save
              </button>
            </div>
          </div>
        </div>

        <div className="form-section">
          <LogOut />
        </div>
      </div>
    )
  }
}

ProfileAbout.propTypes = {
  formData: PropTypes.shape({
    userInfo: PropTypes.shape({
      mediaUri: PropTypes.string.isRequired,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
    }).isRequired,

    bio: PropTypes.shape({
      city: PropTypes.string.isRequired,
      state: PropTypes.string.isRequired,
      birthday: PropTypes.string.isRequired,
      gender: PropTypes.string.isRequired,
      ethnicity: PropTypes.string.isRequired,
    }),
  }).isRequired,

  loading: PropTypes.bool,
  onFieldChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

ProfileAbout.defaultProps = {
  loading: false,
}

const mapStateToProps = state => {
  return {
    loading: state.request.loading,
  }
}

const ProfileWithLoading = withLoadingState(ProfileAbout)

export default connect(mapStateToProps)(ProfileWithLoading)
