import { call, takeEvery, put } from "redux-saga/effects"
import api from "../../utils/api"
import { Auth } from "aws-amplify"

import * as taskSaga from "./taskSaga"


export function* enrollStudy(action) {
  try {
    const session = yield call([Auth, Auth.currentSession])
    const token = session.accessToken.jwtToken
    const user = yield call([Auth, Auth.currentAuthenticatedUser])
    yield call([api, api.setAuthUtility], token)

    yield put({ type: "STUDY_ENROLL_REQUESTED" })

    const studyCode = action.payload.studyCode

    const request = {
      url: api.urlFor(`/studies/enroll/${studyCode}`)
    }

    const response = yield call([api, api.utility.get], request.url)

    if (response.status === 200) {
      yield put({
        type: "STUDY_ENROLL_SUCCEEDED"
      })

      yield call(loadStudies)

      yield call(taskSaga.loadTasks)

      yield put({
        type: "SET_ALERT",
        payload: {
          message: "Great! You've been added to the study!",
          type: "SUCCESS"
        }
      })
    }
    else if (response.status === 404) {
      throw new Error("Study Not Found.")
    }
    else if (response.status === 409) {
      throw new Error("Already Added.")
    }

    if (action.callback) {
      yield call(action.callback)
    }

  } catch (error) {
    const errorMessage = error.message || error

    yield put({
      type: "STUDY_ENROLL_FAILED"
    })

    yield put({
      type: "SET_ALERT",
      payload: {
        message: errorMessage,
        type: "ERROR"
      }
    })

    if (action.callback) {
      yield call(action.callback)
    }
  }
}

export function* loadStudies(action) {
  try {
    const session = yield call([Auth, Auth.currentSession])
    const token = session.accessToken.jwtToken
    const user = yield call([Auth, Auth.currentAuthenticatedUser])
    yield call([api, api.setAuthUtility], token)

    yield put({ type: "STUDIES_LOAD_REQUESTED" })

    const request = {
      url: api.urlFor("/studies/current")
    }

    const response = yield call([api, api.utility.get], request.url)

    if (response.status === 200) {
      yield put({
        type: "STUDIES_LOAD_SUCCEEDED",
        payload: {
          studies: response.data
        }
      })

      yield call(taskSaga.loadTasks)
    }

    if (action && action.callback) {
      yield call(action.callback)
    }

  } catch (error) {
    const errorMessage = error.message || error

    yield put({
      type: "STUDIES_LOAD_FAILED"
    })

    yield put({
      type: "SET_ALERT",
      payload: {
        message: errorMessage,
        type: "ERROR"
      }
    })

    if (action && action.callback) {
      yield call(action.callback)
    }
  }
}

export function* loadStudy(action) {
  try {
    const session = yield call([Auth, Auth.currentSession])
    const token = session.accessToken.jwtToken
    const user = yield call([Auth, Auth.currentAuthenticatedUser])
    yield call([api, api.setAuthUtility], token)

    yield put({ type: "STUDY_LOAD_REQUESTED" })

    const studyId = action.payload.studyId

    const request = {
      url: api.urlFor(`/study/${studyId}`)
    }

    const response = yield call([api, api.utility.get], request.url)

    if (response.status === 200) {
      yield put({
        type: "STUDY_LOAD_SUCCEEDED",
        payload: {
          study: response.data
        }
      })
    }
    else if (response.status === 404) {
      throw new Error("Study Not Found.")
    }

  } catch (error) {
    const errorMessage = error.message || error

    yield put({
      type: "STUDY_LOAD_FAILED"
    })

    yield put({
      type: "SET_ALERT",
      payload: {
        message: errorMessage,
        type: "ERROR"
      }
    })
  } finally {
    if (action.onComplete) {
      yield call(action.onComplete)
    }
  }
}

export default function* studySaga() {
  yield takeEvery("STUDIES_LOAD_SAGA", loadStudies)
  yield takeEvery("STUDY_ENROLL_SAGA", enrollStudy)
  yield takeEvery("STUDY_LOAD_SAGA", loadStudy)
}
