const initialState = {
  loading: false,
}

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action

  if (type) {
    if (/_REQUESTED/.test(type)) {
      return {
        ...state,
        loading: true,
      }
    } else if (/_SUCCEEDED/.test(type)) {
      return {
        ...state,
        loading: false,
      }
    } else if (/_FAILED/.test(type)) {
      return {
        ...state,
        loading: false,
      }
    } else {
      return state
    }
  } else {
    return state
  }
}
