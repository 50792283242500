import React, { Component } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { Storage } from "aws-amplify"

import FileInput from "../../../Elements/FileInput/FileInput"
import { fileToBase64, uploadAvatarToS3 } from "../../../utils/awsUtils"

export class AvatarField extends Component {
  constructor(props) {
    super(props)

    this.state = {
      avatar: "",
    }
  }

  uploadToS3 = async (file) => {
    const base64 = await fileToBase64(file)

    const s3Response = await uploadAvatarToS3(base64, file.name)

    if (s3Response.key) {
      const avatar = await Storage.get("avatar", { level: "protected" })

      this.setState({ avatar })

      this.props.dispatch({
        type: "SET_ALERT",
        payload: {
          type: "SUCCESS",
          message: "Avatar updated"
        }
      })
    }
  }

  componentDidMount() {
    this.getAvatar()
  }

  getAvatar = async () => {
    const avatar = await Storage.get("avatar", { level: "protected" })

    this.setState({ avatar: avatar })
  }

  render() {
    if (!this.state.avatar) {
      return (
        <div className="avatar-container">
          <img alt="avatar-placeholder" src={require("../../../assets/images/profile-lg.png")} />
          <div className="avatar-container__file-input-container">
            <FileInput
              fileTypes={[".png", ".jpg"]}
              onUpload={(data) => { this.uploadToS3(data) }}
              text="Change Photo"
            />
          </div>
        </div>
      )
    } else {
      return (
        <div className="avatar-container">
          <img alt="avatar" className="avatar" src={this.state.avatar} />

          <div className="avatar-container__file-input-container">
            <FileInput
              fileTypes={[".png", ".jpg"]}
              onUpload={(data) => { this.uploadToS3(data) }}
              text="Change Photo"
            />
          </div>
        </div>
      )
    }
  }
}

AvatarField.propTypes = {
  avatarUri: PropTypes.string,
}

export default connect()(AvatarField)
