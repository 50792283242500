import {
  call,
  put,
  fork,
  takeEvery,
  take,
  select,
  all,
} from "redux-saga/effects"
import api from "../../utils/api"

export function* getAllCategories() {
  try {
    yield put({ type: "GET_ALL_CATEGORIES_REQUESTED" })

    const response = yield call(
      [api, api.utility.get],
      api.urlFor("/getAllCategories")
    )

    if (api.isSuccess(response)) {
      yield put({
        type: "GET_ALL_CATEGORIES_SUCCEEDED",
        payload: response.data,
      })
    } else {
      yield put({
        type: "GET_ALL_CATEGORIES_FAILED",
        payload: response.data,
      })
    }
  } catch (e) {
    yield put({
      type: "GET_ALL_CATEGORIES_FAILED",
      payload: e.message,
    })
  }
}

export function* getUserCategories() {
  try {
    yield put({ type: "GET_USER_CATEGORIES_REQUESTED" })

    const response = yield call(
      [api, api.utility.get],
      api.urlFor("/categories")
    )

    if (api.isSuccess(response)) {
      yield put({
        type: "GET_USER_CATEGORIES_SUCCEEDED",
        payload: response.data,
      })
    } else {
      yield put({
        type: "GET_USER_CATEGORIES_FAILED",
        payload: response.data,
      })
    }
  } catch (e) {
    yield put({
      type: "GET_USER_CATEGORIES_FAILED",
      payload: e.message,
    })
  }
}

export function* addUserCategory(action) {
  try {
    yield put({ type: "ADD_USER_CATEGORY_REQUESTED" })

    const body = { categoryId: action.payload }

    const response = yield call([api, api.utility], {
      method: "post",
      url: api.urlFor("/categories"),
      data: body,
    })

    if (api.isSuccess(response)) {
      yield put({
        type: "ADD_USER_CATEGORY_SUCCEEDED",
        payload: action.payload,
      })
    } else {
      yield put({
        type: "ADD_USER_CATEGORY_FAILED",
        payload: response,
      })
    }
  } catch (e) {
    yield put({
      type: "ADD_USER_CATEGORY_FAILED",
      payload: e,
    })
  }
}

export function* deleteUserCategory(action) {
  try {
    yield put({ type: "DELETE_USER_CATEGORY_REQUESTED" })

    const categoryId = action.payload
    const response = yield call(
      [api, api.utility.delete],
      api.urlFor(`/categories/${action.payload}`)
    )

    if (api.isSuccess(response) && response.data.length > 0) {
      const deletedCategoryId = action.payload

      yield put({
        type: "DELETE_USER_CATEGORY_SUCCEEDED",
        payload: deletedCategoryId,
      })
    } else {
      yield put({
        type: "DELETE_USER_CATEGORY_FAILED",
        payload: response.data,
      })
    }
  } catch (e) {
    yield put({
      type: "DELETE_USER_CATEGORY_FAILED",
      payload: e.message,
    })
  }
}

export function* bulkRequests(action) {
  yield all([call(getAllCategories), call(getUserCategories)])

  if (action.onComplete) {
    yield call(action.onComplete)
  }
}

export default function* categoriesSaga() {
  yield takeEvery("GET_ALL_CATEGORIES_SAGA", getAllCategories)
  yield takeEvery("BULK_GET_ALL_CATEGORIES_SAGA", bulkRequests)
  yield takeEvery("GET_USER_CATEGORIES_SAGA", getUserCategories)
  yield takeEvery("ADD_USER_CATEGORY_SAGA", addUserCategory)
  yield takeEvery("DELETE_USER_CATEGORY_SAGA", deleteUserCategory)
}
