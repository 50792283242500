import React, { Component } from "react"
import PropTypes from "prop-types"

import withLoadingState from "../../../Elements/Loading/withLoadingState"

import StudyListItem from "./StudyListItem"


class StudyList extends Component {

  renderListItems() {
    const { onNavigate, studies } = this.props

    return (
      studies.map((study, index) => {
        return (
          <div
            className="col-12 col-md-6 study_list__column"
            key={study.id}
          >
            <StudyListItem
              onNavigate={onNavigate}
              study={study}
            />
          </div>
        )
      })
    )
  }

  render() {
    return (
      <div className="container study_list__container">
        <div className="row">
          {this.renderListItems()}
        </div>
      </div>
    )
  }

} // class StudyList


StudyList.propTypes = {
  onNavigate: PropTypes.func.isRequired,
  studies: PropTypes.array.isRequired,

  loading: PropTypes.bool
}

StudyList.defaultProps = {
  loading: false
}

export default withLoadingState(StudyList)
