import moment from "moment"

export function formattedDate(dateString) {
  if (dateString) {
    return moment(dateString).format("MMMM Do")
  }
  else {
    return null
  }
}

export function formattedDateRange(startDateString, endDateString) {
  const formattedDates = [formattedDate(startDateString), formattedDate(endDateString)]

  return formattedDates.filter((e) => e).join(" - ")
}
