import React, { Component } from "react"
import PropTypes from "prop-types"


class TaskViewHeading extends Component {

  classNameForContainer() {
    const { onNavigate } = this.props

    let className = "container task_view_heading__container"

    if (onNavigate) {
      className += " task_view_heading__container--clickable"
    }

    return className
  }

  renderIcon() {
    const { onNavigate } = this.props

    if (onNavigate) {
      return (
        <div className="task_view_heading__icon_container">
          <img
            alt=""
            className="task_view_heading__icon"
            src={require("../../../assets/images/left-arrow.png")}
          />
        </div>
      )
    }
  }

  render() {
    const { onNavigate, text } = this.props

    return (
      <div
        className={this.classNameForContainer()}
        onClick={onNavigate}
      >
        <div className="row">
          <div className="col-12">
            {this.renderIcon()}

            <h1 className="task_view_heading__text">
              {text}
            </h1>
          </div>
        </div>
      </div>
    )
  }

} // class TaskViewHeading


TaskViewHeading.propTypes = {
  text: PropTypes.string.isRequired,

  onNavigate: PropTypes.func
}

TaskViewHeading.defaultProps = {
  onNavigate: null
}

export default TaskViewHeading
