import React, { Component } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"

import withLoadingState from "../../../Elements/Loading/withLoadingState"
import Loading from "../../../Elements/Loading/Loading"
import TaskQuestionsList from "../Components/TaskQuestionsList"
import TaskQuestion from "../Components/TaskQuestion"
import TaskFormMediaCapture from "../Components/TaskFormMediaCapture"

import { toggleItem } from "../../../utils/arrayHelpers"
import * as FileConstants from "../../../constants/files"
import * as FileUtils from "../../../utils/fileUtils"
import { isChrome } from "../../../utils/browserHelpers";
import { deleteCookie } from "../../../utils/cookieUtils"

export class TaskForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      audioData: [],
      photoData: [],
      videoData: [],
      questions: [],
    }
  }

  componentDidMount() {
    if (sessionStorage.getItem(`tempQuestions_${this.props.task.id}`)) {
      this.setState({ questions: JSON.parse(sessionStorage.getItem(`tempQuestions_${this.props.task.id}`)) })
    } else {
      this.setState({ questions: this.getInitialQuestionState(this.props.task.questions) })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { audioData, photoData, videoData } = this.state
    const prevAudioData = prevState.audioData
    const prevPhotoData = prevState.photoData
    const prevVideoData = prevState.videoData

    if (audioData.length > prevAudioData.length || photoData.length > prevPhotoData.length || videoData.length > prevVideoData.length) {
      this.setMediaCapturedAlert()
    }
  }

  // Map over questions array and mark them all as having a null answer for local form state.
  getInitialQuestionState(questions) {
    return questions.map(q => { return { ...q, answer: null, answers: [] } })
  }

  updateQuestionAnswerField = (targetQuestion, newAnswerState = {}) => {
    const newQuestions = this.state.questions.map(question => {
      if (question.id === targetQuestion.id) {
        return {
          ...targetQuestion,
          ...newAnswerState,
        }
      } else {
        return question
      }
    })

    this.setState({ questions: newQuestions })
  }

  handleQuestionChange = (answer) => {
    const answeredQuestion = this.state.questions.find(q => q.id === answer.questionId)

    if (answeredQuestion.questionType === TaskQuestion.QUESTION_TYPE_CHECKBOX) {
      const newAnswers = toggleItem(answeredQuestion.answers, answer)

      this.updateQuestionAnswerField(answeredQuestion, { answers: newAnswers })
    } else {
      this.updateQuestionAnswerField(answeredQuestion, { answer: answer.description })
    }
  }

  handleSubmit = () => {
    const { audioData, photoData, videoData } = this.state

    deleteCookie("audioOpen")

    sessionStorage.removeItem(`tempQuestions_${this.props.task.id}`)

    this.props.onSubmit({
      ...this.props.task,
      questions: this.state.questions,
      media: {
        "IMAGE": photoData,
        "AUDIO": audioData,
        "VIDEO": videoData,
      },
    })
  }

  setMediaCapturedAlert = () => {
    this.props.dispatch({
      type: "SET_ALERT",
      payload: {
        message: "Media captured!",
        type: "SUCCESS",
      },
    })
  }

  handleCapturedMedia = (data, type) => {
    if (type === TaskFormMediaCapture.MEDIA_VIDEO_CAPTURE) {
      this.setState({ videoData: [...this.state.videoData, data] })
    } else if (type === TaskFormMediaCapture.MEDIA_PHOTO_CAPTURE) {
      this.setState({ photoData: [...this.state.photoData, data] })
    } else if (type === TaskFormMediaCapture.MEDIA_AUDIO_CAPTURE) {
      if (this.validateAudioSize(data)) {
        this.setState({ audioData: [...this.state.audioData, data] })
      }
    }
  }

  validateAudioSize(audioData) {
    const recordingLength = FileUtils.calculateRecordingLength(audioData.startTime, audioData.stopTime)

    if (recordingLength >= FileConstants.MAX_AUDIO_SIZE_MILLISECONDS) {
      return true
    } else {
      this.props.dispatch({
        type: "SET_ALERT",
        payload: {
          message: "It doesn't look like you've said enough! We're looking for at least 10 seconds of recording. Please try again!",
          type: "ERROR",
        }
      })

      return false
    }
  }

  areAnyQuestionsUnanswered = () => {
    return this.state.questions.some(question => {
      return (!question.answer || question.answer.trim() === "") && question.answers.length === 0
    })
  }

  areMediaSubmitted = () => {
    const { task } = this.props
    const { audioData, photoData, videoData, } = this.state

    // If it requires media capture of any kind
    if (task.mediaCapture) {
      return audioData.length > 0 || photoData.length > 0 || videoData.length > 0
    }

    return true
  }

  getDisabledMediaOptions = () => {
    const disabledOpts = []
    const { task } = this.props
    const { audioData, photoData, videoData } = this.state

    if (photoData.length >= task.photoCapture) {
      disabledOpts.push("photo")
    }

    if (audioData.length >= task.audioCapture) {
      disabledOpts.push("audio")
    }

    if (videoData.length >= task.videoCapture) {
      disabledOpts.push("video")
    }

    return disabledOpts
  }

  storeAnswersAndReload = () => {
    if (isChrome()) {
      document.cookie = "audioOpen=true"

      sessionStorage.setItem(`tempQuestions_${this.props.task.id}`, JSON.stringify(this.state.questions))
      window.location.reload()
    }
  }

  render() {
    const { task } = this.props

    return (
      <div className="content-container">
        <div className="content-container__header">
          <p className="content-container__header-subtitle">
            {task.sponsor}
          </p>

          <p className="content-container__header-title">
            {task.name}
          </p>
        </div>

        <TaskFormMediaCapture
          disabledOptions={this.getDisabledMediaOptions()}
          task={task}
          onMediaCapture={this.handleCapturedMedia}
          onDeleteAudioRecording={this.storeAnswersAndReload}
        />

        <TaskQuestionsList
          questions={this.state.questions}
          onChange={this.handleQuestionChange}
        />

        <div className="task-form__submit-container">
          <button
            className="btn primary-app-btn"
            onClick={this.handleSubmit}
            disabled={this.props.submitting || (this.areAnyQuestionsUnanswered() || !this.areMediaSubmitted())}
          >
            {this.props.submitting ? <Loading size="small" /> : "Finish"}
          </button>
        </div>
      </div>
    )
  }
}

TaskForm.propTypes = {
  loading: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
  task: PropTypes.shape({
    study: PropTypes.string.isRequired,
    sponsor: PropTypes.string.isRequired,
  }),
}

TaskForm.defaultProps = {
  loading: false,
  submitting: false,
}

const TaskFormWithLoading = withLoadingState(TaskForm)

export default connect()(TaskFormWithLoading)
