import React, { Component } from "react"
import PropTypes from "prop-types"

import "video.js/dist/video-js.css"
import "videojs-record/dist/css/videojs.record.css"
import VideoModalOptions from "./VideoModalOptions"

import videojs from "video.js"
import "webrtc-adapter"
import Record from "videojs-record/dist/videojs.record.js"

import VideoControlActions from "./VideoControlActions"
import withModal from "../../../Elements/AppModal/withModal"

class VideoModal extends Component {
  constructor(props) {
    super(props)

    this.videoNode = null

    this.initialRecordingState = {
      isRecording: false,
      isPaused: false,
      isStopped: true,
    }

    this.state = {
      recordMode: false,
      recorderReady: false,
      recording: this.initialRecordingState,
    }
  }

  static get PLAYER_OPTIONS() {
    return {
      autoplay: true,
      controls: true,
      width: 320,
      height: 280,
      fluid: false,
      plugins: {
        record: {
          audio: true,
          video: true,
          maxLength: 60,
          debug: true,
        }
      }
    }
  }

  componentWillUnmount() {
    this.player && this.player.record().stopDevice()
  }

  openRecordMode = () => {
    this.setState({ recordMode: true }, () => {
      const player = videojs(this.videoNode, VideoModal.PLAYER_OPTIONS)

      if (!this.player) {
        this.player = player

        this.player.on("ready", () => {
          // Setup default device right away to skip the mic screen.
          this.player.record().getDevice()

          this.setState({ recorderReady: true })
        })

        this.player.on("startRecord", () => {
          this.setState({
            recording: {
              ...this.initialRecordingState,
              isRecording: true,
            }
          })
        })

        this.player.on("finishRecord", () => {
          this.setState({ isRecording: false })

          this.setState({
            recording: {
              ...this.initialRecordingState,
              isRecording: false,
            }
          })

          this.props.onVideoCaptured(this.player.recordedData)
        })
      }
    })
  }

  record = () => {
    this.setState({
      recording: {
        ...this.initialRecordingState,
        isRecording: true,
      }
    })

    this.player.record().start()
  }

  stop = () => {
    this.setState({
      recording: {
        ...this.initialRecordingState,
        isStopped: true,
      }
    })

    this.player.record().stop()
  }

  pause = () => {
    this.setState({
      recording: {
        ...this.initialRecordingState,
        isPaused: true,
      }
    })

    this.player.record().pause()
  }

  resume = () => {
    this.setState({
      recording: {
        ...this.initialRecordingState,
        isRecording: true
      }
    })

    this.player.record().resume()
  }

  getDisabledOptions = () => {
    const { recording } = this.state

    if (recording.isRecording) {
      return ["record", "play"]
    }

    if (recording.isPaused) {
      return ["stop", "pause"]
    }

    if (!recording.isRecording) {
      return ["stop", "pause", "play"]
    }

    return []
  }

  render() {
    if (this.state.recordMode) {
      return (
        <div>
          <h4>Press the red record button below to begin</h4>
          <video
            ref={node => this.videoNode = node}
            style={{width: "100%"}}
            className="video-js">
          </video>

          <VideoControlActions
            disabled={this.getDisabledOptions()}
            isVisible={this.state.recorderReady}
            onPauseClick={this.pause}
            onRecordClick={this.record}
            onResumeClick={this.resume}
            onStopClick={this.stop}
          />
        </div>
      )
    } else {
      return (
        <VideoModalOptions
          onPhotoClick={this.openRecordMode}
          onVideoUpload={(data) => { this.props.onVideoCaptured(data) }}
        />
      )
    }
  }
}

VideoModal.propTypes = {
  onVideoCaptured: PropTypes.func,
}

VideoModal.defaultProps = {
  onVideoCaptured: () => {},
}

export default withModal(VideoModal)
