import React, { Component } from "react"
import { connect } from "react-redux"

import ViewContainer from "../../../Layouts/ViewContainer"
import TabView from "../../../Elements/TabView/TabView"
import ProfileAbout from "../Components/ProfileAbout"
import ProfileAreasOfInterest from "../Components/ProfileAreasOfInterest"
import ProfilePassword from "../Components/ProfilePassword"

class Profile extends Component {
  constructor(props) {
    super(props)

    this.state = {
      formData: {
        userInfo: {
          mediaUri: "",
          firstName: "",
          lastName: "",
          email: "",
        },

        bio: {
          city: "",
          state: "",
          birthday: new Date(),
          gender: "",
          ethnicity: "",
        },
      },
    }
  }

  componentDidMount() {
    this.props.dispatch({
      type: "GET_PROFILE_SAGA",
      onSuccess: (profile) => {
        const parsedForState = this.parseProfile(profile)

        this.setState({ formData: parsedForState })
      }
    })
  }

  parseProfile = (profile) => {
    const sectionNames = ["userInfo", "bio", "passwords", "areasOfInterest"]

    const { formData } = this.state

    sectionNames.map(sectionName => {
      const keys = Object.keys(formData[sectionName])

      keys.forEach(key => {
        if (key === "birthday") {
          if (formData[sectionName].birthday) {
            formData[sectionName][key] = new Date(profile[key]) || formData[sectionName][key]
          }
        } else {
          formData[sectionName][key] = profile[key] || formData[sectionName][key]
        }
      })
    })

    return formData
  }

  updateFormField = (section, fieldName, value) =>  {
    this.setState({
      formData: {
        ...this.state.formData,

        [section]: {
          ...this.state.formData[section],
          [fieldName]: value
        }
      }
    })
  }

  submitForm = (section) => {
    this.props.dispatch({
      type: "UPDATE_PROFILE_SAGA",
      payload: this.state.formData[section],
      onSuccess: (response) => {}
    })
  }

  render() {
    return (
      <ViewContainer>
        <div className="container user-profile">
          <h1 className="user-profile__header">Profile</h1>

          <TabView tabs={["About", "Areas of Interest", "Password"]}>
            <ProfileAbout
              formData={this.state.formData}
              onFieldChange={this.updateFormField}
              onSubmit={this.submitForm}
            />

            <ProfileAreasOfInterest />

            <ProfilePassword />
          </TabView>
        </div>
      </ViewContainer>
    )
  }
}

export default connect()(Profile)

