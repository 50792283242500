import React, { Component } from "react"
import PropTypes from "prop-types"

import withLoadingState from "../../../Elements/Loading/withLoadingState"

import PrimaryButton from "../../../Elements/Button/PrimaryButton"


class TaskShowContent extends Component {

  renderInstructions() {
    const { task } = this.props

    if (task.subTasks) {
      return task.subTasks.map((subTask, index) => {
        return (
          <p key={index}>
            <span className="task_show_content__instruction_label">
              {index + 1}
            </span>

            {subTask.name}
          </p>
        )
      })
    }
  }

  renderRequirements() {
    const { task } = this.props

    let requirementElements = []


    console.log("TASK: ", task)


    if (task.audioCapture > 0) {
      requirementElements.push(
        <div className="col-12 col-md-1 task_show_content__requirement_column">
          <div className="row">
            <div className="col-6 col-md-12 task_show_content__requirement_sub_column">
              <div className="task_show_content__requirement_image_container">
                <img
                  alt=""
                  className="task_show_content__requirement_image"
                  src={require("../../../assets/images/microphoneblk.png")}
                />
              </div>
            </div>

            <div className="col-6 col-md-12 task_show_content__requirement_sub_column">
              Audio
            </div>
          </div>
        </div>
      )
    }

    if (task.photoCapture > 0) {
      requirementElements.push(
        <div className="col-12 col-md-1 task_show_content__requirement_column">
          <div className="row">
            <div className="col-6 col-md-12 task_show_content__requirement_sub_column">
              <div className="task_show_content__requirement_image_container">
                <img
                  alt=""
                  className="task_show_content__requirement_image"
                  src={require("../../../assets/images/camerablk.png")}
                />
              </div>
            </div>

            <div className="col-6 col-md-12 task_show_content__requirement_sub_column">
              Photo
            </div>
          </div>
        </div>
      )
    }

    if (task.videoCapture > 0) {
      requirementElements.push(
        <div className="col-12 col-md-1 task_show_content__requirement_column">
          <div className="row">
            <div className="col-6 col-md-12 task_show_content__requirement_sub_column">
              <div className="task_show_content__requirement_image_container">
                <img
                  alt=""
                  className="task_show_content__requirement_image"
                  src={require("../../../assets/images/videoblk.png")}
                />
              </div>
            </div>

            <div className="col-6 col-md-12 task_show_content__requirement_sub_column">
              Video
            </div>
          </div>
        </div>
      )
    }

    if (task.pollURL) {
      requirementElements = []
    }

    return (
      <div className="row">
        {requirementElements}
      </div>
    )
  }

  render() {
    const { onNavigate, task } = this.props
    let returnRenderedContent = []


    returnRenderedContent.push(

    )
    return (
      <div className="task_show_content__container">
        <div className="task_show_content__heading_container">
          <div className="row">
            <div className="col-12">
              <h4>{task.study}</h4>
              <h1>{task.name}</h1>
            </div>
          </div>
        </div>

        <div className="task_show_content__body_container">
          <div className="row">
            <div className="col-12">
              <p className="task_show_content__description">
                {task.content}
              </p>
            </div>
          </div>

          {!task.pollURL && (task.audioCapture >0 || task.photoCapture || task.videoCapture > 0) &&
          <div className="row">
            <div className="col-12">
              <p className="task_show_content__requirement_heading">
                Task will include
              </p>
            </div>
          </div>
          }

          {this.renderRequirements()}

          {this.renderInstructions()}

          <div className="task_show_content__divider"></div>

          { !task.pollURL &&
            <div className="row">
              <div className="col-12 task_show_content__button_column">
                <PrimaryButton
                  onClick={onNavigate}
                  text="Get Started"
                />
              </div>
            </div>
          }

          { task.pollURL &&
            <div className="row">
              <div className="col-12 task_show_content__button_column">
                <a href={task.pollURL}>Begin External Survey</a>
              </div>
            </div>
          }

        </div>
      </div>
    )
  }

} // class TaskShowContent


TaskShowContent.propTypes = {
  onNavigate: PropTypes.func.isRequired,
  task: PropTypes.object.isRequired,
  loading: PropTypes.bool,
}

TaskShowContent.defaultProps = {
  loading: false,
}

export default withLoadingState(TaskShowContent)
