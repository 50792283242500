import React, { Component, Fragment } from "react"
import PropTypes from "prop-types"

import videojs from "video.js"

import PhotoControlActions from "./PhotoControlActions"
import ModalOptions from "./ModalOptions"
import withModal from "../../../Elements/AppModal/withModal"

import { fileToBase64 } from "../../../utils/awsUtils"

class PhotoModal extends Component {
  constructor(props) {
    super(props)

    this.cameraNode = null
    this.player = null

    this.state = {
      cameraMode: false,
      cameraReady: false,

      imageData: null,
    }
  }

  componentWillUnmount() {
    this.player && this.player.record().stopDevice()
  }

  static get PLAYER_OPTIONS() {
    return {
      controls: false,
      width: 373,
      height: 280,
      fluid: false,
      controlBar: {
        volumePanel: false,
        fullScreenToggle: false,
      },
      plugins: {
        record: {
          image: true,
          debug: true,
        },
      },
    }
  }

  openCameraMode = () => {
    this.setState({ cameraMode: true }, () => {
      const player = videojs(this.cameraNode, PhotoModal.PLAYER_OPTIONS)

      if (!this.player) {
        this.player = player

        this.player.on("ready", () => {
          this.player.record().getDevice()

          this.setState({ cameraReady: true })
        })

        this.player.on("finishRecord", () => {
          this.setState({ imageData: this.player.recordedData })

          this.props.onImageCaptured(this.player.recordedData)
        })
      }
    })
  }

  takePicture = () => {
    this.player && this.player.record().start()
  }

  retryPicture = () => {
    this.setState({ imageData: null })

    this.props.onImageCaptured(null)

    this.player && this.player.record().retrySnapshot()
  }

  getDisabledOptions = () => {
    if (this.state.imageData) {
      return ["camera"]
    } else {
      return ["trash"]
    }
  }

  handleUploadedFile = async (file) => {
    const base64 = await fileToBase64(file)

    this.props.onImageCaptured(base64)
  }

  render() {
    if (this.state.cameraMode) {
      return (
        <Fragment>
          <video
            ref={node => this.cameraNode = node}
            style={{ width: "100%" }}
            className="video-js">
          </video>

          <PhotoControlActions
            onCaptureClick={this.takePicture}
            onDeleteClick={this.retryPicture}
            disabled={this.getDisabledOptions()}
          />
        </Fragment>
      )
    } else {
      return (
        <div>
          <ModalOptions
            actionButtonText="Take A Photo"
            fileTypes={[".png", ".jpg"]}
            headerAsset={require("../../../assets/images/camera.png")}
            onActionButtonClick={this.openCameraMode}
            onFileUpload={(data) => { this.handleUploadedFile(data) }}
            subtitle="Take a photo or choose from your files"
            title="Add Photo"
          />
        </div>
      )
    }
  }
}

PhotoModal.propTypes = {
  onImageCaptured: PropTypes.func.isRequired,
}

export default withModal(PhotoModal)
