import React from "react"
import PropTypes from "prop-types"

import Alert from "../Elements/Alert/Alert"
import MobileNav from "./MobileNav"
import NavBar from "./NavBar"
import MobileTopNav from "./MobileTopNav"


const ViewContainer = props => {
  return (
    <div className="layout-container">
      <MobileTopNav />
      <NavBar />

      {props.children}

      <div className="push"></div>

      <Alert />

      <MobileNav />
    </div>
  )
}

ViewContainer.propTypes = {
  children: PropTypes.any.isRequired,
}

export default ViewContainer
