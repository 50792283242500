const initialState = {
  current: {
    id: null,
    questions: [],
  },
  defaultTask: {},
  list: [],
}

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action

  switch (type) {
  case "GET_DEFAULT_TASK_SUCCEEDED":
    return {
      ...state,
      defaultTask: payload,
    }
  case "TASK_LOAD_SUCCEEDED":
    return {
      ...state,
      current: payload.task,
    }
  case "TASKS_LOAD_SUCCEEDED":
    return {
      ...state,
      list: payload.tasks,
    }
  default:
    return state
  }
}
