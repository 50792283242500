const initialState = {
  message: "",
  type: "DEFAULT"
}

export default function reducer(state = initialState, action = {}) {
  const { payload, type } = action

  switch (type) {
  case "SET_ALERT":
    return {
      ...state,
      message: payload.message,
      type: payload.type,
    }

  case "CLEAR_ALERT":
    return {
      ...state,
      message: "",
      type: "DEFAULT",
    }

  default:
    return state
  }
}
