import React, { Component } from "react"
import PropTypes from "prop-types"

import TaskListItem from "./TaskListItem"


class TaskList extends Component {

  sectionTitle() {
    const { sectionIndex, tasks } = this.props
    return tasks[0].study || `Study ${sectionIndex}`
  }

  renderListItems() {
    const { onNavigate, tasks } = this.props

    return (
      tasks.map((task, itemIndex) => {
        return (
          <div
            className="col-12 task_list_section__column"
            key={task.id}
          >
            <TaskListItem
              itemIndex={itemIndex}
              onNavigate={onNavigate}
              task={task}
            />
          </div>
        )
      })
    )
  }

  render() {
    return (
      <div className="container task_list_section__container">
        <div className="row">
          <div className="col-12">
            <h4 className="task_list_section__heading">
              {this.sectionTitle()}
            </h4>
          </div>
        </div>

        <div className="row">
          {this.renderListItems()}
        </div>
      </div>
    )
  }

} // class TaskList


TaskList.propTypes = {
  onNavigate: PropTypes.func.isRequired,
  sectionIndex: PropTypes.number.isRequired,
  tasks: PropTypes.array.isRequired
}

export default TaskList
