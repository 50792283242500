import React from "react"
import PropTypes from "prop-types"
import FileInput from "../../../Elements/FileInput/FileInput"
import * as browserHelpers from "../../../utils/browserHelpers"
import MobileFileInput from "../../../Elements/MobileFileInput/MobileFileInput"

const isSafari = browserHelpers.isSafari()

const VideoModalOptions = (props) => {
  const { onPhotoClick } = props

  const getMessage = () => {
    if (isSafari) {
      return "Use your phone's camera to record a video and upload it here. Download the Hoot! mobile app and record directly from the app"
    } else {
      return "Take a video or choose from your files"
    }
  }

  return (
    <div className="app-modal__container--inner">
      <div className="icon-container">
        <img alt="video" src={require("../../../assets/images/video.png")} />
      </div>

      <h3 className="app-modal__header">Record Video</h3>

      <p>
        {getMessage()}
      </p>

      <div className="container">
        <div className="row">
          <div className={`col-sm-12 take-a-video ${isSafari ? "col-md-12" : "col-md-6"}`}>
            <FileInput
              onUpload={props.onVideoUpload}
              fileTypes={[".mp4", ".mov", ".webm"]}
            />
          </div>

          <div className={`col-sm-12 show-on-apple ${isSafari ? "col-md-12" : "col-md-6"}`}>
            <MobileFileInput
              onUpload={props.onVideoUpload}
              fileTypes={[".mp4", ".mov", ".webm"]}
            />
          </div>

          <div className="col-sm-12 col-md-6 take-a-video">
            <button onClick={onPhotoClick} className="btn-file">
              Record Video
            </button>

            <p>
              No file chosen
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

VideoModalOptions.propTypes = {
  onPhotoClick: PropTypes.func.isRequired,
  onVideoUpload: PropTypes.func,
}

export default VideoModalOptions
