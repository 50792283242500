import React, { Component } from "react"
import PropTypes from "prop-types"

import * as dateHelpers from "../../../helpers/dateHelpers"


class TaskListItem extends Component {

  isTaskCompleted() {
    const { task } = this.props
    return task.completedDate !== null
  }

  renderTaskName() {
    const { task } = this.props

    let className = "task_list_item__name"

    if (this.isTaskCompleted()) {
      className += " task_list_item__name--completed"
    }

    return (
      <h4 className={className}>
        {task.name}
      </h4>
    )
  }

  renderIcon() {
    if (this.isTaskCompleted()) {
      return (
        <div className="task_list_item__icon">
          <img
            alt=""
            className="task_list_item__icon_image"
            src={require("../../../assets/images/check-sm.png")}
          />
        </div>
      )
    }
    else  {
      return (
        <div className="task_list_item__icon">
          <img
            alt=""
            className="task_list_item__icon_image"
            src={require("../../../assets/images/tasks.png")}
          />
        </div>
      )
    }
  }

  render() {
    const { itemIndex, onNavigate, task } = this.props

    return (
      <div
        className="container task_list_item__container"
        onClick={() => onNavigate(task.id)}
      >
        <div className="row">
          <div className="col-1 col-md-1 task_list_item__icon_column">
            {this.renderIcon()}
          </div>

          <div className="col-1 col-md-1 task_list_item__number_column">
            <div className="task_list_item__number">
              {itemIndex + 1}
            </div>
          </div>

          <div className="col-10 col-md-10 task_list_item__detail_column">
            {this.renderTaskName()}

            <p>
              { !task.openEnded
                ?  <label>Due : {dateHelpers.formattedDate(task.dueDate)}</label>
                : <label>Open Ended</label>
              }
            </p>
          </div>
        </div>
      </div>
    )
  }

} // class TaskListItem


TaskListItem.propTypes = {
  itemIndex: PropTypes.number.isRequired,
  onNavigate: PropTypes.func.isRequired,
  task: PropTypes.object.isRequired
}

export default TaskListItem
