// WARNING: DO NOT EDIT. This file is Auto-Generated by AWS Mobile Hub. It will be overwritten.

// Copyright 2017-2018 Amazon.com, Inc. or its affiliates (Amazon). All Rights Reserved.
// Code generated by AWS Mobile Hub. Amazon gives unlimited permission to
// copy, distribute and modify it.

const awsmobile = {
  aws_app_analytics: "enable",
  aws_cognito_identity_pool_id:
    "us-east-1:2c35ce3a-ea8a-4ac8-ad4a-1c541c1a10ed",
  aws_cognito_region: "us-east-1",
  aws_content_delivery: "enable",
  aws_content_delivery_bucket:
    "hoot-hosting-mobilehub-522230175",
  aws_content_delivery_bucket_region:
    "us-east-1",
  aws_content_delivery_cloudfront:
    "enable",
  aws_content_delivery_cloudfront_domain:
    "d358y97l9o9hme.cloudfront.net",
  aws_mobile_analytics_app_id:
    "d0c840d4573f4894b4eea70f5a3162c5",
  aws_mobile_analytics_app_region:
    "us-east-1",
  aws_project_id: "2580f4b5-dfe2-47fb-b439-2ad6bbb0da89",
  aws_project_name: "hoot",
  aws_project_region: "us-east-1",
  aws_resource_name_prefix: "hoot-mobilehub-522230175",
  aws_sign_in_enabled: "enable",
  aws_user_files: "enable",
  aws_user_files_s3_bucket: "hoot-userfiles-mobilehub-522230175",
  aws_user_files_s3_bucket_region:
    "us-east-1",
  aws_user_pools: "enable",
  aws_user_pools_id: "us-east-1_mtulNeGo9",
  aws_user_pools_web_client_id:
    "ngmhlgaf05b7j7i661hvsgsg",

  Storage: {
    bucket: "hoot-userfiles-mobilehub-522230175",
    region: "us-east-1",
  },
}

export default awsmobile
