import { combineReducers } from "redux"

import alert from "./reducers/alert"
import category from "./reducers/category"
import notification from "./reducers/notification"
import request from "./reducers/request"
import study from "./reducers/study"
import task from "./reducers/task"
import user from "./reducers/user"

const appReducer = combineReducers({
  alert,
  category,
  notification,
  request,
  study,
  task,
  user,
})

export default appReducer
