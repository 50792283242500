import React, { Component } from "react"
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom"
import { connect } from "react-redux"
import createHistory from "history/createBrowserHistory"

import { routerPaths } from "./routes"

// *** VIEWS ***
import DashboardView from "./Views/Dashboard/DashboardView"
import ReviewNew from "./Contexts/Reviews/Views/ReviewNew"
import ReviewSuccess from "./Contexts/Reviews/Views/ReviewSuccess"
import StudyIndex from "./Contexts/Studies/Views/StudyIndex"
import StudyShow from "./Contexts/Studies/Views/StudyShow"
import TaskComplete from "./Contexts/Tasks/Views/TaskComplete"
import TaskIndex from "./Contexts/Tasks/Views/TaskIndex"
import TaskShow from "./Contexts/Tasks/Views/TaskShow"
import TaskSuccess from "./Contexts/Tasks/Views/TaskSuccess"
import Profile from "./Contexts/Users/Views/Profile"
import Notifications from "./Contexts/Notifications/Views/Notifications"
import RuntimeError from "./Contexts/Errors/Views/RuntimeError"

import Analytics from "react-router-ga"


class ApplicationRouter extends Component {
  clearAlert(ComponentToRender, routeProps={}) {
    this.props.dispatch({
      type: "CLEAR_ALERT"
    })

    return <ComponentToRender {...routeProps} />
  }

  render() {
    const VALID_AUTH_STATES = ["signedIn"]

    if (VALID_AUTH_STATES.includes(this.props.authState)) {
      return (
        <Router>
          <Analytics id="UA-136750969-1" debug>
          <Switch>
            <Route
              exact
              path={routerPaths.error}
              render={(props) => this.clearAlert(RuntimeError, props)}
            />

            {this.props.error && <Redirect from="/" to={routerPaths.error} />}

            <Route
              exact
              path={routerPaths.dashboard}
              render={(props) => this.clearAlert(DashboardView, props)}
            />

            <Route
              exact
              path={routerPaths.reviewsNew}
              render={(props) => this.clearAlert(ReviewNew, props)}
            />

            <Route
              exact
              path={routerPaths.reviewsSuccess}
              render={(props) => this.clearAlert(ReviewSuccess, props)}
            />

            <Route
              exact
              path={routerPaths.studies}
              render={(props) => this.clearAlert(StudyIndex, props)}
            />

            <Route
              exact
              path={routerPaths.studiesShow}
              render={(props) => this.clearAlert(StudyShow, props)}
            />

            <Route
              exact
              path={routerPaths.tasks}
              render={(props) => this.clearAlert(TaskIndex, props)}
            />

            <Route
              exact
              path={routerPaths.tasksComplete}
              render={(props) => this.clearAlert(TaskComplete, props)}
            />

            {/* NOTE: This needs to be above TasksShow so that show doesn't confuse success as an ID */}
            <Route
              exact
              path={routerPaths.tasksSuccess}
              render={(props) => this.clearAlert(TaskSuccess, props)}
            />

            <Route
              exact
              path={routerPaths.tasksShow}
              render={(props) => this.clearAlert(TaskShow, props)}
            />

            <Route
              exact
              path={routerPaths.usersProfile}
              render={(props) => this.clearAlert(Profile, props)}
            />

            <Route
              exact
              path={routerPaths.notifications}
              render={(props) => this.clearAlert(Notifications, props)}
            />

          </Switch>
          </Analytics>
        </Router>
      )
    }

    return null
  }
}

export default connect()(ApplicationRouter)
