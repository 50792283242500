import React, { Fragment } from "react"

import { matchPath } from "react-router"

import NavLink from "./NavLink"
import { routerPaths } from "../routes"

import { getAsset } from "../utils/navHelpers"

const MobileNav = props => {
  const links = [
    <NavLink
      name="Review"
      route={routerPaths.dashboard}
      isMobile={true}
      asset={require("../assets/images/reviewDrk.png") /* TODO: Need to get blue asset */}
    />,

    <NavLink
      name="Tasks"
      route={routerPaths.tasks}
      isMobile={true}
      asset={getAsset(routerPaths.tasks, "tasks")}
    />,

    <NavLink
      name="Studies"
      route={routerPaths.studies}
      isMobile={true}
      asset={getAsset(routerPaths.studies, "study")}
    />,

    <NavLink
      name="Profile"
      route={routerPaths.usersProfile}
      isMobile={true}
      asset={getAsset(routerPaths.usersProfile, "profile")}
    />,

    <NavLink
      name="Alerts"
      route={routerPaths.notifications}
      isMobile={true}
      asset={getAsset(routerPaths.notifications, "notification")}
    />,
  ]

  return (
    <div className="mobile-nav">
      {links.map((link, index) => <Fragment key={index}>{link}</Fragment>)}
    </div>
  )
}

export default MobileNav
