import React from "react"
import PropTypes from "prop-types"
import { Modal, ModalBody, ModalFooter } from "reactstrap"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/free-solid-svg-icons"

const AppModal = (props) => {
  const { buttonText, children, isOpen, onClose, renderButton } = props

  return (
    <Modal isOpen={isOpen} toggle={onClose}>
      <ModalBody>
        <a href="javascript:void(0)" className="app-modal__close" onClick={onClose}>
          <FontAwesomeIcon icon={faTimes}/>
        </a>

        {children}
      </ModalBody>

      <ModalFooter>
        <div className="row">
          {renderButton && renderButton()}
        </div>
      </ModalFooter>
    </Modal>
  )
}

AppModal.propTypes = {
  children: PropTypes.any.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  renderButton: PropTypes.func,
}

export default AppModal
