import { Storage } from "aws-amplify"

export async function uploadBase64ToS3(base64String, fileName) {
  const options = {
    ContentEncoding: "base64",
    contentType: "image/png",
    level: "private",
  }

  const response = await fetch(base64String)
  const blob = await response.blob()

  const s3Response = await Storage.put(fileName, blob, options)

  return s3Response
}

export async function uploadAvatarToS3(base64String, fileName) {
  const options = {
    ContentEncoding: "base64",
    contentType: "image/png",
    level: "protected",
  }

  const response = await fetch(base64String)
  const blob = await response.blob()

  const s3Response = Storage.put("avatar", blob, options)

  return s3Response
}

export async function uploadAudioToS3(blob, fileName) {
  let s3FileName = fileName

  if (!s3FileName) {
    s3FileName = `${new Date().valueOf()}.wav`
  }

  const options = {
    contentType: "audio/*",
    level: "private",
  }

  try {
    const s3Response = await Storage.put(s3FileName, blob, options)

    return s3Response
  } catch(e) {
    return false
  }
}

export async function uploadVideoToS3(data, fileName) {
  // Set a UTC timestamp on the filename
  const timestampedFileName = `${new Date().getTime()}${fileName}`

  const options = {
    contentType: "video/webm",
    level: "private",
  }

  const s3Response = await Storage.put(timestampedFileName, data,  options)

  return s3Response
}

export function fileToBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject
  })
}
