import React, { Component } from "react"
import { connect } from "react-redux"

import Loading from "../../../Elements/Loading/Loading"
import CheckBoxList from "../../../Elements/CheckBoxList/CheckBoxList"
import { getCategoriesWithSelectedStatus } from "../../../actions/selectors/categorySelector"

class ProfileAreasOfInterest extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: false,
    }
  }

  componentDidMount() {
    // NOTE: If we've already fetched categories, no need to fetch again.
    if (this.props.categories.length === 0) {
      this.setState({ loading: true })

      this.props.dispatch({
        type: "BULK_GET_ALL_CATEGORIES_SAGA",
        onComplete: () => { this.setState({ loading: false }) }
      })
    }
  }

  handleCheckToggle = option => {
    if (!option.isChecked) {
      this.props.dispatch({
        type: "ADD_USER_CATEGORY",
        payload: { ...option, categoryId: option.id },
      })
      this.props.dispatch({
        type: "ADD_USER_CATEGORY_SAGA",
        payload: option.id,
      })
    } else {
      this.props.dispatch({ type: "REMOVE_USER_CATEGORY", payload: option })
      this.props.dispatch({
        type: "DELETE_USER_CATEGORY_SAGA",
        payload: option.id,
      })
    }
  }

  renderContent = () => {
    if (this.state.loading) {
      return <Loading />
    } else {
      return (
        <CheckBoxList
          options={this.props.categories}
          onChange={this.handleCheckToggle}
        />
      )
    }
  }

  render() {
    return (
      <div>
        <h1>Areas of Interest</h1>

        {this.renderContent()}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    categories: getCategoriesWithSelectedStatus(state),
  }
}

export default connect(mapStateToProps)(ProfileAreasOfInterest)

