import React from "react"
import PropTypes from "prop-types"
import AudioButton from "../../../Elements/AudioButton/AudioButton"

const VideoControlActions = props => {
  const { disabled, isVisible, onRecordClick, onStopClick, onPauseClick, onResumeClick } = props

  if (isVisible) {
    return (
      <div className="container">
        <div className="video-control row">
          <AudioButton
            src={require("../../../assets/images/record-red.png")} 
            onClick={onRecordClick}
            isDisabled={disabled.includes("record")}
          />

          <AudioButton
            src={require("../../../assets/images/stop.png")}
            onClick={onStopClick}
            isDisabled={disabled.includes("stop")}
          />

          <AudioButton
            src={require("../../../assets/images/pause.png")}
            onClick={onPauseClick}
            isDisabled={disabled.includes("pause")}
          />

          <AudioButton
            src={require("../../../assets/images/path-10.png")}
            onClick={onResumeClick}
            isDisabled={disabled.includes("play")}
          />
        </div>
      </div>
    )
  }

  return null
}

VideoControlActions.propTypes = {
  disabled: PropTypes.arrayOf(["stop", "pause", "play", "record"]),
  isVisible: PropTypes.bool,
  onRecordClick: PropTypes.func.isrequired,
  onStopClick: PropTypes.func.isRequired,
  onPauseClick: PropTypes.func.isRequired,
  onResumeClick: PropTypes.func.isRequired,
}

VideoControlActions.defaultProps = {
  isVisible: true,
}

export default VideoControlActions
