export const tasksToSubmissions = (tasks) => {
  const submissions = Object.values(tasks).map((task)=> {

    const subObj = {
      taskId: task.taskId
    }

    if (task.questions) {
      subObj.questions = formatSubmissionQuestions(task.questions)
    }

    return subObj
  })

  return submissions
}

export const formatSubmissionQuestions = (questionsObj) => {
  return Object.values(questionsObj).map((question) => {

    if (question.answers.length > 0) {
      /* It's a checkbox answer with multiple answers */
      return {
        answers: question.answers,
        questionId: question.id,
        details: formatCheckboxAnswers(question)
      }

    } else {

      return ({
        answer: question.answer,
        questionId: question.id,
      })
    }
  })
}

export const formatCheckboxAnswers = (question) => {
  return question.details.map(detail => {
    // Take out id & description because we don't need them.
    const { id, description, ...restOfDetail } = detail

    return {
      ...restOfDetail,
      valueId: id,
      value: description,
    }
  })
}

export function formatMediaPayload(mediaType, url) {
  return {
    mediaType,
    url,
  }
}
