import React from "react"

import { navigationPaths } from "../../../routes"

// TODO: Change navigationPage to task index once added.

import SuccessDisplay from "../../Shared/Components/SuccessDisplay"
import ViewContainer from "../../../Layouts/ViewContainer"

const TaskSuccess = () => {
  return (
    <ViewContainer>
      <SuccessDisplay
        headingText="Your task is complete!"
        navigationPath={navigationPaths.tasks()}
      />
    </ViewContainer>
  )
}

export default TaskSuccess
