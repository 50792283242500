import React from "react"
import PropTypes from "prop-types"

const Loading = props => {
  return (
    <div className={`loader ${props.size}`}>
      Loading...
    </div>
  )
}

Loading.propTypes = {
  size: PropTypes.oneOf(["small", "large"])
}

Loading.defaultProps = {
  size: "large",
}

export default Loading
