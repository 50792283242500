import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

export const NotificationIcon = props => {
  const { notificationCount } = props

  let asset

  if (notificationCount > 0) {
    asset = require("../../assets/images/notification-alert.png")
  } else {
    asset = require("../../assets/images/notification.png")
  }

  return (
    <img alt="notification-icon" src={asset} />
  )
}

NotificationIcon.propTypes = {
  notificationCount: PropTypes.number.isRequired,
}

const mapStateToProps = state => {
  return {
    notificationCount: state.notification.all.length,
  }
}

export default connect(mapStateToProps)(NotificationIcon)