import React, { Component, Fragment } from "react"
import PropTypes from "prop-types"

class FileInput extends Component {
  constructor(props) {
    super(props)

    this.input = null

    this.state = {
      fileName: null,
    }
  }

  handleClick = () => this.input.click()

  handleFileChange = () => {
    const filePath = this.input.value

    if (filePath) {
      const fileName = filePath.replace(/^.*?([^\\\/]*)$/, "$1")

      this.setState({ fileName })

      this.props.onUpload && this.props.onUpload(this.input.files[0])
    }
  }

  render() {
    return (
      <Fragment>
        <input
          accept={this.props.fileTypes.join(",")}
          ref={(ref) => { this.input = ref }}
          type="file"
          onChange={this.handleFileChange}
          style={{ display: "none" }}
        />

        <button className="btn-file" onClick={this.handleClick}>
          {this.props.text}
        </button>

        <p>{this.state.fileName || "No file chosen"}</p>
      </Fragment>
    )
  }
}

FileInput.propTypes = {
  fileTypes: PropTypes.arrayOf(PropTypes.string),
  onUpload: PropTypes.func,
  text: PropTypes.string,
}

FileInput.defaultProps = {
  fileTypes: [],
  onUpload: null,
  text: "Upload File",
}

export default FileInput
