import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Prompt } from "react-router"
import { Storage } from "aws-amplify"

import ViewContainer from "../../../Layouts/ViewContainer"

import ReviewForm from "../Components/ReviewForm"
import { uploadBase64ToS3, uploadAudioToS3, uploadVideoToS3 } from "../../../utils/awsUtils"

import { navigationPaths } from "../../../routes"

import awsconfig from "../../../aws-exports.js"

Storage.configure(awsconfig.Storage)

class ReviewNew extends Component {
  constructor(props) {
    super(props)

    const navigationParams = this.props.location.state || {}

    this.state = {
      loading: false,

      formData: {
        mediaType: navigationParams.mediaType,
        mediaData: navigationParams.mediaData,

        comment: "",
        company: "",
        productName: "",
        category: "",
        location: "",
        share: false,
      },
    }
  }

  static get REQUIRED_FIELDS() {
    return [
      "company",
      "productName",
    ]
  }

  componentDidMount() {
    this.props.dispatch({ type: "GET_DEFAULT_TASK_SAGA" })
  }

  updateField = (fieldName, value) => {
    this.setState({
      formData: {
        ...this.state.formData,
        [fieldName]: value,
      }
    })
  }

  submit = async () => {
    const { mediaData } = this.state.formData
    const { task } = this.props
    const [question] = task.questions
    let uploadResponse
    let uploadURL

    this.setState({ loading: true })

    // TODO: Move this to submission saga
    if (this.state.formData.mediaType === "IMAGE") {
      uploadResponse = await uploadBase64ToS3(mediaData.data, `${mediaData.name}.png`)
      uploadURL = await Storage.get(uploadResponse.key, { level: "private" })
    } else if (this.state.formData.mediaType === "AUDIO") {
      uploadResponse = await uploadAudioToS3(mediaData.blob, `${mediaData.startTime}.wav`)
      uploadURL = await Storage.get(uploadResponse.key, { level: "private" })
    } else {
      uploadResponse = await uploadVideoToS3(mediaData, mediaData.name)
      uploadURL = await Storage.get(uploadResponse.key, { level: "private" })
    }

    this.props.dispatch({
      type: "CREATE_SUBMISSION_SAGA",
      payload: {
        ...this.state.formData,
        task,
        question,
        attachedMedia: [
          {
            mediaType: this.state.formData.mediaType,
            url: uploadURL,
          }
        ],
      },

      onSuccess: () => {
        this.props.history.push({
          pathname: navigationPaths.reviewsSuccess()
        })
      },

      onFailure: () => {
        this.setState({ loading: false })
        alert("Something went wrong when processing the request")
      }
    })
  }

  isFormDisabled = () => {
    if (this.state.loading) {
      return true
    }

    return ReviewNew.REQUIRED_FIELDS.some(field => {
      return this.state.formData[field].trim() === ""
    })
  }

  render() {
    return (
      <ViewContainer>
        {/* Use loading state to detect if the form is submitting, if it is, don't show the beforeunload prompt */}
        <Prompt
          when={!this.state.loading}
          message="You have unsaved changes, are you sure you want to leave?"
        />

        <div className="review-new container">
          <div className="review-new__header section-header">
            <h3 className="section-header">
              Review
            </h3>
          </div>

          <ReviewForm
            formData={this.state.formData}
            onFieldChange={this.updateField}
            onSubmit={this.submit}
            disabled={this.isFormDisabled()}
            submitting={this.state.loading}
          />
        </div>
      </ViewContainer>
    )
  }
}

ReviewNew.propTypes = {
  media: PropTypes.object,
}

const mapStateToProps = state => {
  return {
    task: state.task.defaultTask,
  }
}

export default connect(mapStateToProps)(ReviewNew)
