import React, { Component } from "react"
import PropTypes from "prop-types"

import ReactAudioPlayer from "react-audio-player"
import { ReactMic } from "react-mic-plus"

import AppModal from "../../../Elements/AppModal/AppModal"
import AudioButton from "../../../Elements/AudioButton/AudioButton"
import PrimaryButton from "../../../Elements/Button/PrimaryButton"
import Timer from "../../../Elements/Timer/Timer"
import { deleteCookie } from "../../../utils/cookieUtils"

const Cookies = require("js-cookie")

class AudioModal extends Component {
  constructor(props) {
    super(props)

    this.timerRef = React.createRef()

    this.state = {
      isPlaying: false,
      isRecording: false,
      isPaused: false,
      media: null,
    }
  }

  componentWillUnmount() {
    clearInterval(this.playbackTracker)
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isOpen && !this.props.isOpen) {
      Cookies.remove("audioOpen", { path: "" })
    }
  }

  allowSubmit = () => {
    const { media } = this.state
    return media !== null
  }

  handleSubmit = () => {
    const { onSubmit } = this.props

    // TODO: reset modal/media state if needed. Handle this in onClose rather than here?

    onSubmit("AUDIO", this.state.media)
  }

  pausePlayback = () => {
    this.setState({
      isPlaying: false
    }, () => {
      this.player.audioEl.pause()
    })
  }

  resetPlayback = () => {
    this.setState({
      isPlaying: false,
    })
  }

  startPlayback = () => {
    if (!this.state.isPaused) {
      this.timerRef.resetTimer()
    }

    this.setState({
      isPlaying: true,
      isPaused: false,
    }, () => {
      this.player.audioEl.play()
    })
  }

  deleteRecording = () => {
    this.props.onDeleteRecording && this.props.onDeleteRecording()

    clearInterval(this.playbackTracker)

    this.setState({
      isPlaying: false,
      isPaused: false,
      media: null,
      playbackTime: {
        current: null
      },
      recordingTime: {
        current: null,
        start: null
      }
    })

    this.timerRef.resetTimer()
  }

  startRecording = () => {
    this.setState({
      isRecording: true,
    })
  }

  stopRecording = () => {
    this.setState({
      isRecording: false
    })
  }

  onRecordingData = (recordedBlob) => {}

  onRecordingStop = (recordedBlob) => {
    this.setState({
      media: recordedBlob,
      isRecording: false,
    })

    this.props.onAudioCaptured && this.props.onAudioCaptured(recordedBlob)
  }

  renderMediaButtons() {
    const { isPlaying, isRecording, media } = this.state

    if (media === null) {
      return (
        <div className="audio_modal__media_buttons">
          {/* RECORD BUTTON */}
          <AudioButton
            isDisabled={isRecording || media !== null}
            onClick={this.startRecording}
            src={require("../../../assets/images/microphone.png")}
          />

          {/* STOP BUTTON */}
          <AudioButton
            isDisabled={!isRecording}
            onClick={this.stopRecording}
            src={require("../../../assets/images/stop.png")}
          />
        </div>
      )
    }
    else {
      return (
        <div className="audio_modal__media_buttons">
          {/* PLAY BUTTON */}
          <AudioButton
            isDisabled={isPlaying}
            onClick={() => {
              this.startPlayback()
            }}
            src={require("../../../assets/images/path-10.png")}
          />

          {/* PAUSE BUTTON */}
          <AudioButton
            isDisabled={!isPlaying}
            onClick={() => {
              this.setState({ isPaused: true })
              this.pausePlayback()
            }}
            src={require("../../../assets/images/pause.png")}
          />

          <AudioButton
            onClick={this.deleteRecording}
            src={require("../../../assets/images/delete.png")}
          />
        </div>
      )
    }
  }

  renderModalContent() {
    return (
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <h3 className="app-modal__header audio_modal__heading">
              Add Audio Recording
            </h3>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12">
            <Timer
              isTiming={this.state.isRecording || this.state.isPlaying}
              ref={timer => this.timerRef = timer}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12">
            {this.renderRecorderOrPlayer()}
            {this.renderMediaButtons()}
          </div>
        </div>
      </div>
    )
  }

  renderRecorderOrPlayer() {
    const { media } = this.state

    if (media === null) {
      return (
        <ReactMic
          record={this.state.isRecording}
          className="audio_modal__recorder"
          onStop={this.onRecordingStop}
          onData={this.onRecordingData}
          strokeColor="#000000"
          backgroundColor="#58bafa"
        />
      )
    }
    else {
      return (
        <ReactAudioPlayer
          onEnded={this.resetPlayback}
          ref={(element) => { this.player = element }}
          src={media.blobURL}
        />
      )
    }
  }

  renderSubmitButton = () => {
    const { buttonText } = this.props

    return (
      <PrimaryButton
        isDisabled={!this.allowSubmit()}
        onClick={this.handleSubmit}
        text={buttonText}
      />
    )
  }

  render() {
    const { isOpen, onClose } = this.props

    return (
      <AppModal
        isOpen={isOpen}
        onClose={onClose}
        renderButton={this.renderSubmitButton}
      >
        {this.renderModalContent()}
      </AppModal>
    )
  }
} // class AudioModal


AudioModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onDeleteRecording: PropTypes.func,

  buttonText: PropTypes.string
}

AudioModal.propTypes = {
  buttonText: "Submit",
  onDeleteRecording: null,
}

export default AudioModal
