import React, { Component } from "react"
import PropTypes from "prop-types"

import { getBirthYears, MONTHS } from "../../constants/dates"

class DatePicker extends Component {
  handleMonthChange = (month) => {
    const newDate = this.props.date

    newDate.setMonth(month)
    // NOTE: Just reset the day to 1 to not have to deal with
    // months that don't have enough days for an already selected day.
    // This can be handled more gracefully later.
    newDate.setDate(1)

    this.props.onChange(newDate)
  }

  handleDayChange = (day) => {
    const newDate = this.props.date

    newDate.setDate(day)

    this.props.onChange(newDate)
  }

  handleYearChange = (year) => {
    const newDate = this.props.date

    newDate.setYear(year)

    this.props.onChange(newDate)
  }

  renderDayField = () => {
    const { date } = this.props
    const monthKeys = Object.keys(MONTHS)

    const currentMonthKey = Object.keys(MONTHS)[date.getMonth()]

    const daysInMonth = MONTHS[currentMonthKey].days

    let days = []

    for (var i = 1; i <= daysInMonth; i ++) {
      days.push(i)
    }

    return (
      <select
        className="date-select"
        name="day"
        value={date.getDate()}
        onChange={(e) => { this.handleDayChange(e.target.value) }}
      >
        {days.map((day, index) => {
          return (
            <option key={index} value={day}>
              {day}
            </option>
          )
        })}
      </select>
    )
  }

  renderYearField = () => {
    const years = getBirthYears()
    const { date } = this.props

    return (
      <select
        className="date-select"
        name="year"
        value={date.getFullYear()}
        onChange={(e) => { this.handleYearChange(e.target.value) }}
      >
        {years.map((year, index) => {
          return (
            <option key={index} value={year}>
              {year}
            </option>
          )
        })}
      </select>
    )
  }

  getMonthValue = () => {
    const { date } = this.props

    return date.getMonth()
  }

  render() {
    const { date } = this.props

    return (
      <div className="row">
        <div className="col-md-3">
          <select
            className="date-select"
            name="month"
            value={date.getMonth()}
            onChange={(e) => { this.handleMonthChange(e.target.value) }}
          >
            {Object.keys(MONTHS).map((monthKey, index) => {
              return (
                <option key={index} value={MONTHS[monthKey].number - 1}>
                  {MONTHS[monthKey].name}
                </option>
              )
            })}
          </select>
        </div>

        <div className="col-md-3">
          {this.renderDayField()}
        </div>

        <div className="col-md-3">
          {this.renderYearField()}
        </div>
      </div>
    )
  }
}

DatePicker.propTypes = {
  date: PropTypes.instanceOf(Date),
  onChange: PropTypes.func.isRequired,
}

export default DatePicker
