export const getBirthYears = () => {
  const years = []
  const currentYear = new Date().getFullYear()

  for (var i = 1900; i <= currentYear; i ++) {
    // NOTE: The years start comin' and they don't stop comin'
    years.push(i)
  }

  return years
}

export const MONTHS = {
  Jan: {
    name: "January",
    short: "Jan",
    number: 1,
    days: 31,
  },
  Feb: {
    name: "February",
    short: "Feb",
    number: 2,
    days: 28,
  },
  Mar: {
    name: "March",
    short: "Mar",
    number: 3,
    days: 31,
  },
  Apr: {
    name: "April",
    short: "Apr",
    number: 4,
    days: 30,
  },
  May: {
    name: "May",
    short: "May",
    number: 5,
    days: 31,
  },
  Jun: {
    name: "June",
    short: "Jun",
    number: 6,
    days: 30,
  },
  Jul: {
    name: "July",
    short: "Jul",
    number: 7,
    days: 31,
  },
  Aug: {
    name: "August",
    short: "Aug",
    number: 8,
    days: 31,
  },
  Sep: {
    name: "September",
    short: "Sep",
    number: 9,
    days: 30,
  },
  Oct: {
    name: "October",
    short: "Oct",
    number: 10,
    days: 31,
  },
  Nov: {
    name: "November",
    short: "Nov",
    number: 11,
    days: 30,
  },
  Dec: {
    name: "December",
    short: "Dec",
    number: 12,
    days: 31,
  },
}
