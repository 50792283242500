import React, { Component } from "react"
import { connect } from "react-redux"

import ViewContainer from "../../../Layouts/ViewContainer"
import NotificationsList from "../Components/NotificationsList"
import NotificationRow from "../Components/NotificationRow"

import { routerPaths } from "../../../routes"

class Notifications extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isFetching: false,
      deletingNotification: {},
    }
  }

  componentDidMount() {
    this.setState({ isFetching: true })

    this.props.dispatch({
      type: "GET_NOTIFICATIONS_SAGA",
      onComplete: () => {
        this.setState({ isFetching: false })
      }
    })
  }

  handleNotificationClick = (notification, type) => {
    if (!type) { return }

    if (type === NotificationRow.TYPE_STUDY) {
      this.props.history.push({
        pathname: routerPaths.studies
      })
    } else if (type === NotificationRow.TYPE_TASK) {
      this.props.history({
        pathname: routerPaths.tasks
      })
    }
  }

  handleNotificationDelete = (notification) => {
    this.setState({ deletingNotification: notification })

    this.props.dispatch({
      type: "REMOVE_NOTIFICATION_SAGA",
      payload: {
        notificationId: notification.id,
      },
      onComplete: () => {
        this.setState({ deletingNotification: {} })
      }
    })
  }

  render() {
    return (
      <ViewContainer>
        <div className="container">
          <h1 className="notifications-header">
            Notifications
          </h1>

          <div className="notifications-container content-container">
            <NotificationsList
              loading={this.state.isFetching}
              notifications={this.props.notifications}
              onNotificationClick={this.handleNotificationClick}
              onNotificationDelete={this.handleNotificationDelete}
              deletingNotification={this.state.deletingNotification}
            />
          </div>
        </div>
      </ViewContainer>
    )
  }
}

const mapStateToProps = state => {
  return {
    notifications: state.notification.all,
  }
}

export default connect(mapStateToProps)(Notifications)
