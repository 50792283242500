import React from "react"
import PropTypes from "prop-types"
import TaskQuestion, { taskQuestionPropTypes } from "./TaskQuestion"
import TaskFormBlock from "./TaskFormBlock"

const TaskQuestionsList = (props) => {
  return props.questions.map((question, index) => {
    return (
      <TaskFormBlock content={question.name} key={index}>
        <TaskQuestion onChange={props.onChange} question={question} />
      </TaskFormBlock>
    )
  })
}

TaskQuestionsList.propTypes = {
  onChange: PropTypes.func.isRequired,
  questions: PropTypes.arrayOf(PropTypes.shape({
    ...taskQuestionPropTypes.question
  })),
}

export default TaskQuestionsList