import React, { Component } from "react"
import PropTypes from "prop-types"

// TODO (MPH): We probably want to update the ViewContiner to have a content wrapper div around its children render, and be consistent across all views, not just tasks. Then eliminate this. We will to check styling of non-task views after change.


class TaskViewContainer extends Component {

  render() {
    const { children } = this.props

    return (
      <div className="container task_view_container">
        {children}
      </div>
    )
  }

} // class TaskViewContainer


TaskViewContainer.propTypes = {
  children: PropTypes.any.isRequired
}

export default TaskViewContainer
