import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import { navigationPaths } from "../../../routes"

import StudyEnrollmentForm from "../Components/StudyEnrollmentForm"
import StudyList from "../Components/StudyList"
import ViewContainer from "../../../Layouts/ViewContainer"


class StudyIndex extends Component {

  constructor(props) {
    super(props)

    this.state = {
      loading: true
    }
  }

  componentDidMount() {
    this.props.dispatch({
      type: "STUDIES_LOAD_SAGA",
      callback: () => {
        this.setState({
          loading: false
        })
      }
    })
  }

  navigateToStudy = (studyId) => {
    this.props.history.push({
      pathname: navigationPaths.studiesShow(studyId),
    })
  }

  setLoading = () => {
    this.setState({
      loading: true
    })
  }

  unsetLoading = () => {
    this.setState({
      loading: false
    })
  }

  render() {
    const { studies } = this.props
    const { loading } = this.state

    return (
      <ViewContainer>
        <div className="container study_index__container">
          <div className="row study_index__row">
            <div className="col-sm-12">
              <h1>Studies</h1>
            </div>
          </div>

          <div className="row study_index__row">
            <div className="col-sm-12">
              <StudyEnrollmentForm
                onSetLoading={this.setLoading}
                onUnsetLoading={this.unsetLoading}
              />
            </div>
          </div>

          <div className="row study_index__row">
            <div className="col-sm-12">
              <StudyList
                loading={loading}
                onNavigate={this.navigateToStudy}
                studies={studies}
              />
            </div>
          </div>
        </div>
      </ViewContainer>
    )
  }

} // class StudyIndex


StudyIndex.propTypes = {
  studies: PropTypes.array.isRequired
}

const mapStateToProps = (state) => {
  return {
    studies: state.study.list
  }
}

export default connect(mapStateToProps)(StudyIndex)
