import React, { Component } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"

import * as dateHelpers from "../../../helpers/dateHelpers"
import { navigationPaths } from "../../../routes"
import withLoadingState from "../../../Elements/Loading/withLoadingState"

import PrimaryButton from "../../../Elements/Button/PrimaryButton"
import ViewContainer from "../../../Layouts/ViewContainer"


class StudyShowContent extends Component {
  buildFeedbackString() {
    const { study } = this.props

    const captureRequirements = {
      audio: study.audioCapture || 0,
      photo: study.photoCapture || 0,
      video: study.videoCapture || 0
    }

    let feedbackStrings = []

    if (captureRequirements.audio > 0) {
      let feedbackString = `${captureRequirements.audio} audio recording`
      if (captureRequirements.audio > 1) { feedbackStrings += "s" }
      feedbackStrings.push(feedbackString)
    }

    if (captureRequirements.photo > 0) {
      let feedbackString = `${captureRequirements.photo} photo upload`
      if (captureRequirements.photo > 1) { feedbackStrings += "s" }
      feedbackStrings.push(feedbackString)
    }

    if (captureRequirements.video > 0) {
      let feedbackString = `${captureRequirements.video} video recording`
      if (captureRequirements.video > 1) { feedbackStrings += "s" }
      feedbackStrings.push(feedbackString)
    }

    return feedbackStrings.join(", ")
  }

  render() {
    const { study } = this.props

    return (
      <div className="container study_show__container">
        <div className="row study_show__header_row">
          <div className="col-12">
            <h1>Studies</h1>
          </div>
        </div>

        <div className="row study_show__image_row">
          <div className="col-12">
            <img
              alt=""
              className="study_show__image"
              src={study.imageUri}
            />
            <img
              alt=""
              className="study_show__image_mobile"
              src={study.mobileImageUri}
            />
          </div>
        </div>

        <div className="study_show__content_container">
          <div className="row study_show__name_row">
            <div className="col-12 col-md-8">
              <h1>{study.name}</h1>
            </div>

            <div className="col-12 col-md-4 study_show__button_column_top">
              <Link
                className="btn primary-app-btn"
                to={{ pathname: navigationPaths.tasks() }}
              >
                View Tasks
              </Link>
            </div>
          </div>

          <div className="row study_show__content_row">
            <div className="col-12">
              <p>{study.description || study.content}</p>
            </div>
          </div>

          <div className="study_show__divider"></div>

          <div className="row study_show__details_row">
            <div className="col-12">
              {/*<p><span className="study_show__label">What It Takes</span></p>*/}

              { (study.startDate && study.endDate)
                ? <p><span className="study_show__label">Dates: </span>{dateHelpers.formattedDateRange(study.startDate, study.endDate)}</p>
                : <p><span className="study_show__label">Dates: </span>Open Ended</p>

              }
{/*              <p><span className="study_show__label">Feedback Needed: </span>{this.buildFeedbackString()}</p>*/}
            </div>
          </div>

          {/* WARNING: The API never appears to return study.taskDescription or an alternative */}
          {study.taskDescription && <div>
            <div className="study_show__divider"></div>

            <div className="row study_show__more_details_row">
              <div className="col-12">
                <p><span className="study_show__label">More Details</span></p>
                <p>{study.taskDescription}</p>
              </div>
            </div>

            <div className="row study_show__button_row">
              <div className="col-12 study_show__button_column_bottom">
                <Link
                  className="btn primary-app-btn"
                  to={{ pathname: navigationPaths.tasks() }}
                >
                  View Tasks
                </Link>
              </div>
            </div>
          </div>}
        </div>
      </div>
    )
  }
} // class StudyShowContent

StudyShowContent.propTypes = {
  loading: PropTypes.bool.isRequired,
  onNavigate: PropTypes.func.isRequired,
  study: PropTypes.object.isRequired
}

export default withLoadingState(StudyShowContent)
