import React from "react"
import ViewContainer from "../../../Layouts/ViewContainer"

import { Link } from "react-router-dom"

import { routerPaths } from "../../../routes"

const RuntimeError = () => {
  return (
    <ViewContainer>
      <div className="container error-container">
        <img alt="error" src={require("../../../assets/images/owl.png")} />

        <h1>Oops! We've encountered a problem!</h1>

        <Link className="btn primary-app-btn" to={{ pathname: routerPaths.dashboard }}>
          Take Me Home
        </Link>
      </div>
    </ViewContainer>
  )
}

export default RuntimeError