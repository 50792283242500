import React, { Component } from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"

import { navigationPaths } from "../../routes"

import DashboardButton from "../../Elements/DashboardButton/DashboardButton"
import Jumbotron from "../../Elements/Jumbotron/Jumbotron"
import AudioModal from "../../Contexts/Reviews/Components/AudioModal"
import VideoModal from "../../Contexts/Reviews/Components/VideoModal"
import PhotoModal from "../../Contexts/Reviews/Components/PhotoModal"
import ViewContainer from "../../Layouts/ViewContainer"
import { isChrome } from "../../utils/browserHelpers"
import { getCookie, deleteCookie } from "../../utils/cookieUtils"

class DashboardView extends Component {
  constructor(props) {
    super(props)

    this.state = {
      modal: {
        type: "",
        isOpen: false,
      },

      mediaVideo: null,
      mediaImage: null,
    }
  }

  componentDidMount() {
    const audioCookieResult = getCookie("audioOpen")

    if (audioCookieResult) {
      // If the audiOpen cookie is present, open the audio modal right away.
      this.setState({
        modal: {
          type: "AUDIO",
          isOpen: true,
        }
      })

      // Then clear the cookie, as it should no longer be used.
      deleteCookie("audioOpen")
    }

    // Fetch app wide data
    this.props.dispatch({ type: "GET_NOTIFICATIONS_SAGA" })
  }

  openModal = modalType => {
    this.setState({
      ...this.state,
      modal: {
        type: modalType,
        isOpen: true,
      },
    })
  }

  closeModal = () => {
    this.setState({
      ...this.state,
      modal: {
        type: "",
        isOpen: false,
      },
    })
  }

  navigateToReviewForm = (mediaType, mediaData) => {
    this.props.history.push({
      pathname: navigationPaths.reviewsNew(),
      state: {
        mediaData: mediaData,
        mediaType: mediaType
      }
    })
  }

  renderModal() {
    if (this.state.modal.type === "AUDIO") {
      return (
        <AudioModal
          buttonText="Add to Review"
          isOpen={this.state.modal.isOpen}
          onClose={this.closeModal}
          onSubmit={this.navigateToReviewForm}
          onDeleteRecording={() => {
            if (isChrome()) {
              document.cookie = "audioOpen=true"
              window.location.reload()
            }
          }}
        />
      )
    } else if (this.state.modal.type === "VIDEO") {
      const { mediaVideo } = this.state

      return (
        <VideoModal
          isOpen={this.state.modal.isOpen}
          onClose={this.closeModal}
          onVideoCaptured={videoData => {
            this.setState({ mediaVideo: videoData })
          }}
          renderButton={() => {
            return (
              <Link
                className={`btn primary-app-btn ${mediaVideo === null ? "disabled" : ""}`}
                to={{
                  pathname: "/reviews/new",
                  state: {
                    mediaType: "VIDEO",
                    mediaData: this.state.mediaVideo,
                  },
                }}
              >
                Add To Review
              </Link>
            )
          }}
        />
      )
    } else if (this.state.modal.type === "PHOTO") {
      // TODO: Need to reset captured media state when modal closes
      return (
        <PhotoModal
          isOpen={this.state.modal.isOpen}
          onClose={this.closeModal}
          onImageCaptured={imageData => {
            this.setState({
              mediaImage: {
                data: imageData,
                name: `${Date.now()}`
              }
            })
          }}
          renderButton={() => {
            return this.renderModalButton({
              disabled: this.state.mediaImage === null,
              params: {
                mediaType: "IMAGE",
                mediaData: this.state.mediaImage
              },
            })
          }}
        />
      )
    }
  }

  renderModalButton({ disabled, params }) {
    return (
      <Link
        className={`btn primary-app-btn ${disabled ? "disabled" : ""}`}
        to={{ pathname: "/reviews/new", state: params, }}
      >
          Add to Review
      </Link>
    )
  }

  render() {
    const DASHBOARD_ITEMS = [
      {
        props: {
          text: "Add Photo",
          onClick: () => {
            this.openModal("PHOTO")
          },
          icon: DashboardButton.ICON_CAMERA,
        },
      },

      {
        props: {
          text: "Add Video",
          onClick: () => {
            this.openModal("VIDEO")
          },
          icon: DashboardButton.ICON_VIDEO,
        },
      },

      {
        props: {
          text: "Add Audio",
          onClick: () => {
            this.openModal("AUDIO")
          },
          icon: DashboardButton.ICON_MICROPHONE,
        },
      },

      {
        props: {
          text: "Write",
          onClick: () => {
            this.navigateToReviewForm("TEXT", "")
          },
          icon: DashboardButton.ICON_PEN,
        },
      },
    ]

    return (
      <ViewContainer>
        <Jumbotron text="Tell companies what you think about their products and services." />

        <div className="custom-container">
          <div className="container dashboard-container">
            <div className="row dashboard-title">
              <div className="col-12">
                <p className="header">
                  What would you like to talk about today?
                </p>
              </div>
            </div>

            <div className="dashboard__tile_container container-fluid">
              <div className="row">
                {DASHBOARD_ITEMS.map((item, index) => (
                  <div className="dashboard__column_wrapper col-lg-3 col-md-4 col-6">
                    <DashboardButton key={index} {...item.props} />
                  </div>
                ))}
              </div>
            </div>
          </div>

          {this.renderModal()}
        </div>
      </ViewContainer>
    )
  }
}

export default connect()(DashboardView)
