import React from "react"

const Jumbotron = ({ text }) => {
  return (
    <div className="custom-jumbotron">
      <h1 className="custom-jumbotron--text">{text}</h1>
    </div>
  )
}

export default Jumbotron
