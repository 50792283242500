import React, { Component, Fragment } from "react"
import PropTypes from "prop-types"

import TabHeader from "./TabHeader"

class TabView extends Component {
  constructor(props) {
    super(props)

    this.state = {
      tabs: props.tabs,
      active: 0,
    }
  }

  selectTab = (tab) => {
    this.setState({ active: tab })
  }

  render() {
    return (
      <Fragment>
        <TabHeader
          active={this.state.active}
          onSelect={this.selectTab}
          tabs={this.state.tabs}
        />

        <div className="tab-view">
          {this.props.children[this.state.active]}
        </div>
      </Fragment>
    )
  }
}

TabView.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    component: PropTypes.node.isRequired,
  })).isRequired,
}

export default TabView

