import React from "react"
import PropTypes from "prop-types"

export const TabHeader = props => {
  const { active, tabs, onSelect } = props

  return (
    <div className="tab-view__header row">
      {tabs.map((tab, index) => {
        const isActive = index === active

        return (
          <div
            className={`tab-view__tab ${isActive ? "tab-view__tab--active" : ""}`}
            key={index}
            onClick={() => { onSelect(index) }}
          >
            <p className="tab-view__tab-text">
              {tab}
            </p>
          </div>
        )
      })}
    </div>
  )
}

TabHeader.propTypes = {
  active: PropTypes.number.isRequired,
  onSelect: PropTypes.func.isRequired,
  tabs: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default TabHeader
