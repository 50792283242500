import React from "react"
import PropTypes from "prop-types"
import FileInput from "../../../Elements/FileInput/FileInput"

import { isMobileSafari } from "../../../utils/browserHelpers"

// TODO: We can use this component in VideoModal
const ModalOptions = props => {
  const {
    actionButtonText,
    fileTypes,
    headerAsset,
    onActionButtonClick,
    onFileUpload,
    subtitle,
    title,
  } = props

  return (
    <div className="app-modal__container--inner">
      <div className="icon-container">
        <img alt="modal-header-img" src={headerAsset} />
      </div>

      <h3 className="app-modal__header">{title}</h3>

      <p>{subtitle}</p>

      <div className="container">
        <div className="row">
          <div className={`col-sm-12 ${!isMobileSafari() ? "col-md-6" : "col-md-12"}`}>
            <FileInput onUpload={onFileUpload} fileTypes={fileTypes} />
          </div>

          {!isMobileSafari() &&<div className="col-sm-12 col-md-6">
            <button onClick={onActionButtonClick} className="btn-file">
              {actionButtonText}
            </button>

            <p>No file chosen</p>
          </div>}
        </div>
      </div>
    </div>
  )
}

ModalOptions.propTypes = {
  actionButtonText: PropTypes.string.isRequired,
  headerAsset: PropTypes.any, // should be a require()
  onActionButtonClick: PropTypes.func.isRequired,
  onFileUpload: PropTypes.func.isRequired,
  fileTypes: PropTypes.arrayOf(PropTypes.string),
  subtitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

ModalOptions.defaultProps = {
  fileTypes: []
}

export default ModalOptions
