import {
  all,
  call,
  cancel,
  cancelled,
  fork,
  put,
  race,
  select,
  take,
  takeEvery,
  takeLatest,
} from "redux-saga/effects"

import { Auth } from "aws-amplify"

import api from "../../utils/api"

export function* createSubmission(action) {
  try {
    const { payload } = action

    yield put({ type: "CREATE_SUBMISSION_REQUESTED" })

    const session = yield call([Auth, Auth.currentSession])

    const token = session.accessToken.jwtToken

    const user = yield call([Auth, Auth.currentAuthenticatedUser])

    yield call([api, api.setAuthUtility], token)

    const body = {
      company: payload.company,
      category: payload.category,
      comment: payload.comment,
      location: payload.location,
      productName: payload.productName,
      questions: [{ questionId: payload.question.id }],
      share: payload.share,
      taskId: payload.task.id,
      userId: user.attributes.sub,
      attachedMedia: payload.attachedMedia,
    }

    const response = yield call([api, api.utility], {
      method: "post",
      url: api.urlFor("/submission/create"),
      data: body,
    })

    if (response.status === 200) {
      yield put({ type: "CREATE_SUBMISSION_SUCCEEDED" })

      if (action.onSuccess) {
        yield call(action.onSuccess)
      }
    }

  } catch (error) {
    yield put({ type: "CREATE_SUBMISSION_FAILED" })

    yield call(action.onFailure)
  }
}

export function* updateSubmission(action) {}

export function* updateSubmissions(action) {}

export function* uploadFile(action) {}

export function* getFile(action) {}

/* Wrapper around create submission so that it can be optionally cancelled */
export function* raceSubmission(action) {
  yield race({
    task: call(createSubmission, action),
    cancel: take("CANCEL_SUBMISSION_SAGA"),
  })
}

export default function* submissionSaga() {
  // yield takeEvery("CREATE_SUBMISSION_SAGA", raceSubmission)
  yield takeEvery("CREATE_SUBMISSION_SAGA", createSubmission)
  yield takeEvery("UPDATE_SUBMISSION_SAGA", updateSubmission)
  yield takeEvery("UPDATE_SUBMISSIONS_SAGA", updateSubmissions)
}
