import React, { Component } from "react"
import PropTypes from "prop-types"

class Timer extends Component {
  constructor(props) {
    super(props)

    this.timerInterval = null

    this.state = {
      seconds: 0,
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.isTiming && this.props.isTiming) {
      this.startRecording()
    } else if (prevProps.isTiming && !this.props.isTiming) {
      this.stopRecording()
    }
  }

  componentWillUnmount() {
    this.stopRecording()
  }

  resetTimer() {
    this.setState({ seconds: 0 })
  }

  startRecording = () => {
    this.timerInterval = window.setInterval(() => {
      this.setState({ seconds: this.state.seconds + 1 })
    }, 1000)
  }

  stopRecording = () => {
    window.clearInterval(this.timerInterval)
  }

  strPadLeft(string, pad, length) {
    return (new Array(length + 1).join(pad) + string).slice(-length)
  }

  render() {
    const minutes = Math.floor(this.state.seconds / 60)
    const seconds = this.state.seconds - minutes * 60

    return (
      <div className="audio_modal__time">
        {`${this.strPadLeft(minutes, "0", 2)}:${this.strPadLeft(seconds, "0", 2)}`}
      </div>
    )
  }
}

Timer.defaultProps = {
  isTiming: PropTypes.bool.isRequired,
}

export default Timer