import { call, put, takeEvery } from "redux-saga/effects"

import { Auth } from "aws-amplify"

export function* updatePassword(action) {
  try {
    const { password, newPassword } = action.payload

    const user = yield call([Auth, Auth.currentAuthenticatedUser])

    const passwordResponse = yield call([Auth, Auth.changePassword], user, password, newPassword)

    if (passwordResponse === "SUCCESS") {
      yield put({
        type: "SET_ALERT",
        payload: {
          message: "Password updated",
          type: "SUCCESS",
        },
      })
    }

  } catch (error) {
    yield put({
      type: "SET_ALERT",
      payload: {
        message: error.message,
        type: "ERROR",
      },
    })
  }
}

export default function* passwordSaga() {
  yield takeEvery("UPDATE_PASSWORD_SAGA", updatePassword)
}
