import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import { navigationPaths } from "../../../routes"

import TaskShowContent from "../Components/TaskShowContent"
import TaskViewContainer from "../Components/TaskViewContainer"
import TaskViewHeading from "../Components/TaskViewHeading"
import ViewContainer from "../../../Layouts/ViewContainer"


class TaskShow extends Component {
  constructor(props) {
    super(props)

    this.state = {
      taskId: this.props.match.params.id,
      fetching: false,
    }
  }

  componentDidMount() {
    this.setState({ fetching: true })

    this.props.dispatch({
      type: "TASK_LOAD_SAGA",
      payload: {
        taskId: this.state.taskId
      },

      onComplete: () => {
        this.setState({ fetching: false })
      }
    })
  }

  navigateBack = () => {
    this.props.history.push({
      pathname: navigationPaths.tasks(),
    })
  }

  navigateToTaskComplete = () => {
    this.props.history.push({
      pathname: navigationPaths.tasksComplete(this.state.taskId),
    })
  }

  render() {
    const { task } = this.props

    return (
      <ViewContainer>
        <TaskViewContainer>

          <div className="row">
            <div className="col-12">
              <TaskViewHeading
                onNavigate={this.navigateBack}
                text="Task List"
              />
            </div>
          </div>

          <TaskShowContent
            loading={this.state.fetching}
            onNavigate={this.navigateToTaskComplete}
            task={task}
          />

        </TaskViewContainer>
      </ViewContainer>
    )
  }
} // class TaskShow


TaskShow.propTypes = {
  loading: PropTypes.bool.isRequired,
  task: PropTypes.object.isRequired
}

const mapStateToProps = (state) => {
  return {
    loading: state.request.loading,
    task: state.task.current
  }
}

export default connect(mapStateToProps)(TaskShow)
