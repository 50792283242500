import React, { Component } from "react"
import { connect } from "react-redux"

import { navigationPaths } from "../../../routes"

import TaskViewContainer from "../Components/TaskViewContainer"
import TaskViewHeading from "../Components/TaskViewHeading"
import ViewContainer from "../../../Layouts/ViewContainer"
import TaskForm from "./TaskForm"

class TaskComplete extends Component {
  constructor(props) {
    super(props)

    this.state = {
      taskId: this.props.match.params.id,
      fetching: false,
      isSubmitting: false,
    }
  }

  componentDidMount() {
    this.setState({ fetching: true })

    this.props.dispatch({
      type: "TASK_LOAD_SAGA",
      payload: {
        taskId: this.state.taskId
      },

      onComplete: () => {
        this.setState({ fetching: false })
      }
    })
  }

  navigateBack = () => {
    this.props.history.push({
      pathname: navigationPaths.tasksShow(this.state.taskId),
    })
  }

  submitTask = (formData) => {
    this.setState({ isSubmitting: true })

    this.props.dispatch({
      type: "SUBMIT_TASKS_SAGA",
      payload: {
        tasks: [formData],
      },

      onSuccess: () => {
        this.props.history.push({
          pathname: navigationPaths.tasksSuccess(),
        })
      },

      onComplete: () => {
        this.setState({ isSubmitting: false })
      }
    })
  }

  render() {
    return (
      <ViewContainer>
        <TaskViewContainer>
          <div className="row">
            <div className="col-12">
              <TaskViewHeading
                onNavigate={this.navigateBack}
                text="Current Task"
              />
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <TaskForm
                task={this.props.task}
                onSubmit={this.submitTask}
                loading={this.state.fetching}
                submitting={this.state.isSubmitting}
              />
            </div>
          </div>
        </TaskViewContainer>
      </ViewContainer>
    )
  }
} // class TaskComplete


const mapStateToProps = (state) => {
  return {
    task: state.task.current
  }
}

export default connect(mapStateToProps)(TaskComplete)
