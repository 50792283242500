import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import { navigationPaths } from "../../../routes"
import * as taskHelpers from "../../../helpers/taskHelpers"

import TabView from "../../../Elements/TabView/TabView"
import TaskList from "../Components/TaskList"
import TaskViewContainer from "../Components/TaskViewContainer"
import TaskViewHeading from "../Components/TaskViewHeading"
import ViewContainer from "../../../Layouts/ViewContainer"


class TaskIndex extends Component {
  componentDidMount() {
    if (this.props.tasks.length === 0) {
      this.props.dispatch({
        type: "TASKS_LOAD_SAGA"
      })
    }
  }

  navigateToTask = (taskId) => {
    this.props.history.push({
      pathname: navigationPaths.tasksShow(taskId),
    })
  }

  render() {
    const { loading, tasks } = this.props

    return (
      <ViewContainer>
        <TaskViewContainer>
          <div className="row">
            <div className="col-12">
              <TaskViewHeading
                text="Task List"
              />
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <TabView tabs={["To Do", "Completed"]}>
                <TaskList
                  loading={loading}
                  onNavigate={this.navigateToTask}
                  tasks={tasks}
                />

                <TaskList
                  loading={loading}
                  onNavigate={this.navigateToTask}
                  tasks={taskHelpers.filterByCompleted(tasks)}
                />
              </TabView>
            </div>
          </div>
        </TaskViewContainer>
      </ViewContainer>
    )
  }
} // class TaskIndex


TaskIndex.propTypes = {
  loading: PropTypes.bool.isRequired,
  tasks: PropTypes.array.isRequired
}

const mapStateToProps = (state) => {
  return {
    loading: state.request.loading,
    tasks: state.task.list
  }
}

export default connect(mapStateToProps)(TaskIndex)
