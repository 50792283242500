import React, { Component, Fragment } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"

import AudioModal from "../../../Contexts/Reviews/Components/AudioModal"
import DashboardButton from "../../../Elements/DashboardButton/DashboardButton"
import PhotoModal from "../../../Contexts/Reviews/Components/PhotoModal"
import TaskFormBlock from "./TaskFormBlock"
import VideoModal from "../../../Contexts/Reviews/Components/VideoModal"

import { getCookie, deleteCookie } from "../../../utils/cookieUtils"

class TaskFormMediaCapture extends Component {
  constructor(props) {
    super(props)

    this.state = {
      audioData: {},
      videoData: {},
      photoData: {},
      modal: {
        type: "",
        isOpen: false,
      }
    }
  }

  static get MEDIA_AUDIO_CAPTURE() {
    return "audioCapture"
  }

  static get MEDIA_PHOTO_CAPTURE() {
    return "photoCapture"
  }

  static get MEDIA_VIDEO_CAPTURE() {
    return "videoCapture"
  }

  componentDidMount() {
    const audioCookie = getCookie("audioOpen")

    if (audioCookie) {
      this.openModal("audio")
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.modal.type === "audio") {
      deleteCookie("audioOpen")
    }
  }

  closeModal = () => {
    this.setState({
      audioData: {},
      videoData: {},
      photoData: {},
      modal: {
        type: "",
        isOpen: false,
      }
    })
  }

  openModal = (type) => {
    this.setState({
      modal: {
        type: type,
        isOpen: true,
      }
    })
  }

  handleCapturedMedia = (data, type) => {
    this.props.onMediaCapture(data, type)
  }

  renderModalButton = (media, type) => {
    return (
      <button
        className="btn primary-app-btn"
        onClick={() => { this.persistMedia(media, type) }}>
        Save
      </button>
    )
  }

  persistMedia = (data, type) => {
    this.props.onMediaCapture(data, type)
    this.closeModal()
  }

  renderField = (fieldType, renderKey) => {
    const { modal } = this.state
    const { disabledOptions } = this.props

    if (this.props.task[fieldType] <= 0) {
      return null
    }

    switch (fieldType) {
    case TaskFormMediaCapture.MEDIA_VIDEO_CAPTURE:
      return (
        <div key={renderKey} className="task-form__media-capture-button-container col-lg-3 col-md-3 col-12">
          <DashboardButton
            disabled={disabledOptions.includes("video")}
            icon={DashboardButton.ICON_VIDEO}
            onClick={() => { this.openModal("video") }}
            text="Reply with Video"
          />

          <VideoModal
            isOpen={modal.isOpen && modal.type === "video"}
            onClose={this.closeModal}
            onVideoCaptured={(data) => { this.setState({ videoData: data }) }}
            renderButton={() => { return this.renderModalButton(this.state.videoData, TaskFormMediaCapture.MEDIA_VIDEO_CAPTURE) }}
          />
        </div>
      )
    case TaskFormMediaCapture.MEDIA_PHOTO_CAPTURE:
      return (
        <div key={renderKey} className="task-form__media-capture-button-container col-lg-3 col-md-6 col-12">
          <DashboardButton
            disabled={disabledOptions.includes("photo")}
            icon={DashboardButton.ICON_CAMERA}
            onClick={() => { this.openModal("photo") }}
            text="Reply with Photo"
          />

          <PhotoModal
            isOpen={modal.isOpen && modal.type === "photo"}
            onClose={this.closeModal}
            onImageCaptured={(data) => { this.setState({ photoData: data })}}
            renderButton={() => { return this.renderModalButton(this.state.photoData, TaskFormMediaCapture.MEDIA_PHOTO_CAPTURE) }}
          />
        </div>
      )
    case TaskFormMediaCapture.MEDIA_AUDIO_CAPTURE:
      return (
        <div key={renderKey} className="task-form__media-capture-button-container col-lg-3 col-md-6 col-12">
          <DashboardButton
            disabled={disabledOptions.includes("audio")}
            icon={DashboardButton.ICON_MICROPHONE}
            onClick={() => { this.openModal("audio") }}
            text="Reply with Audio"
          />

          <AudioModal
            isOpen={modal.isOpen && modal.type === "audio"}
            onClose={this.closeModal}
            onSubmit={(mediaType, mediaData) => {
              this.closeModal()
              this.handleCapturedMedia(mediaData, TaskFormMediaCapture.MEDIA_AUDIO_CAPTURE)
            }}
            onDeleteRecording={() => {
              this.props.onDeleteAudioRecording && this.props.onDeleteAudioRecording()
            }}
            buttonText="Save"
          />
        </div>
      )
    default:
      return null
    }
  }

  render() {
    const { task } = this.props

    const mediaFields = ["audioCapture", "photoCapture", "videoCapture"]

    return (
      <div>
        <TaskFormBlock content={task.taskDescription}>
          <div className="row">
            {mediaFields.map((mediaField, index) => {
              return this.renderField(mediaField)
            })}
          </div>
        </TaskFormBlock>
      </div>
    )
  }
}

TaskFormMediaCapture.propTypes = {
  disabledOptions: PropTypes.array,
  onMediaCapture: PropTypes.func.isRequired,
}

TaskFormMediaCapture.defaultProps = {
  disabledOptions: [],
}

function mapStateToProps(state) {
  return {
    test: state.user.test,
  }
}

export default connect(mapStateToProps)(TaskFormMediaCapture)
