import {
  all,
  call,
  takeEvery,
  put
} from "redux-saga/effects"
import {
  Auth,
  Storage,
} from "aws-amplify"

import * as dataHelpers from "../../utils/dataHelpers"

import { setAuthApi } from "./authSaga"

import api from "../../utils/api"
import {
  uploadVideoToS3,
  uploadAudioToS3,
  uploadBase64ToS3
} from "../../utils/awsUtils"

export function* getDefaultTask() {
  try {
    yield put({
      type: "GET_DEFAULT_TASK_REQUESTED"
    })

    const response = yield call(
      [api, api.utility.get],
      api.urlFor("/tasks/default")
    )

    if (response.status >= 200) {
      yield put({
        type: "GET_DEFAULT_TASK_SUCCEEDED",
        payload: response.data,
      })
    } else {
      yield put({
        type: "GET_DEFAULT_TASK_FAILED",
        payload: response,
      })
    }
  } catch (e) {
    yield put({
      type: "GET_DEFAULT_TASK_FAILED",
      payload: e.message,
    })
  }
}

export function* loadTasks(action) {
  try {
    const session = yield call([Auth, Auth.currentSession])
    const token = session.accessToken.jwtToken
    const user = yield call([Auth, Auth.currentAuthenticatedUser])
    yield call([api, api.setAuthUtility], token)

    yield put({
      type: "TASKS_LOAD_REQUESTED"
    })

    const incompleteTasksRequest = {
      url: api.urlFor("/tasks/current"),
    }

    const incompleteTasksResponse = yield call(
      [api, api.utility.get],
      incompleteTasksRequest.url
    )

    let incompleteTasks = []

    if (incompleteTasksResponse.status >= 200) {
      incompleteTasks = incompleteTasksResponse.data
    }

    const completedTasksRequest = {
      url: api.urlFor("/tasks/completed"),
    }

    const completedTasksResponse = yield call(
      [api, api.utility.get],
      completedTasksRequest.url
    )

    let completedTasks = []

    if (completedTasksResponse.status >= 200) {
      completedTasks = completedTasksResponse.data
    }

    const allTasks = incompleteTasks.concat(completedTasks)

    yield put({
      type: "TASKS_LOAD_SUCCEEDED",
      payload: {
        tasks: allTasks,
      },
    })
  } catch (error) {
    const errorMessage = error.message || error

    yield put({
      type: "TASKS_LOAD_FAILED",
    })

    yield put({
      type: "SET_ALERT",
      payload: {
        message: errorMessage,
        type: "ERROR",
      },
    })
  }
}

export function* loadTask(action) {
  try {
    const session = yield call([Auth, Auth.currentSession])
    const token = session.accessToken.jwtToken
    const user = yield call([Auth, Auth.currentAuthenticatedUser])
    yield call([api, api.setAuthUtility], token)

    yield put({
      type: "TASK_LOAD_REQUESTED"
    })

    const taskId = action.payload.taskId

    const url = api.urlFor(`/task/${taskId}`)

    const response = yield call([api, api.utility.get], url)

    if (response.status === 200) {
      yield put({
        type: "TASK_LOAD_SUCCEEDED",
        payload: {
          task: response.data,
        },
      })
    } else if (response.status === 404) {
      throw new Error("Task Not Found.")
    }
  } catch (error) {
    const errorMessage = error.message || error

    yield put({
      type: "TASK_LOAD_FAILED",
    })

    yield put({
      type: "SET_ALERT",
      payload: {
        message: errorMessage,
        type: "ERROR",
      },
    })
  } finally {
    if (action.onComplete) {
      yield call(action.onComplete)
    }
  }
}

function* uploadMediaToS3(type, data) {
  let response
  if (type === "VIDEO") {
    response = yield call(uploadVideoToS3, data, data.name)
  } else if (type === "AUDIO") {
    response = yield call(uploadAudioToS3, data, data.name)
  } else if (type === "IMAGE") {
    response = yield call(uploadBase64ToS3, data, `hoot_${new Date().valueOf()}.png`)
  }

  if (!response.key) {
    throw new Error("Failed to save media")
  } else {

    const media = yield call(Storage.get, response.key, {
      level: "private"
    })

    return media
  }
}

export function* submitTask(taskSubmission) {
  yield call(setAuthApi)

  try {
    yield put({
      type: "SUBMIT_TASK_REQUESTED",
      payload: taskSubmission
    })

    const videoUrls = yield all(taskSubmission.media["VIDEO"].map(d => uploadMediaToS3("VIDEO", d)))
    const audioUrls = yield all(taskSubmission.media["AUDIO"].map(d => uploadMediaToS3("AUDIO", d.blob)))
    const imageUrls = yield all(taskSubmission.media["IMAGE"].map(d => uploadMediaToS3("IMAGE", d)))

    const mediaBody = []

    videoUrls.forEach(url => mediaBody.push(dataHelpers.formatMediaPayload("VIDEO", url)))

    audioUrls.forEach(url => mediaBody.push(dataHelpers.formatMediaPayload("AUDIO", url)))

    imageUrls.forEach(url => mediaBody.push(dataHelpers.formatMediaPayload("IMAGE", url)))

    const { id, media, ...taskForSubmission } = taskSubmission

    const questionsForSubmission = dataHelpers.formatSubmissionQuestions(taskSubmission.questions)

    const requestBody = {
      ...taskForSubmission,
      questions: questionsForSubmission,
      attachedMedia: mediaBody,
    }

    const response = yield call([api, api.utility], {
      method: "post",
      url: api.urlFor("/submission/create"),
      data: requestBody,
    })

    if (response.status === 200) {
      const completeTaskSuccess = yield call(completeTask, {
        payload: {
          taskId: taskSubmission.taskId
        }
      })

      if (completeTaskSuccess) {
        yield put({ type: "SUBMIT_TASK_SUCCEEDED" })

        return true

      } else {
        yield put({ type: "SUBMIT_TASK_FAILED" })
        return false
      }

    } else {
      yield put({ type: "SUBMIT_TASK_FAILED", payload: response })
      return false
    }
  } catch (e) {
    yield put({ type: "SUBMIT_TASK_FAILED", payload: e.message })
    yield put({
      type: "SET_ALERT",
      payload: {
        payload: e.message,
        type: "ERROR",
      }
    })
  }
}

export function* getCompletedTasks() {
}

export function* completeTask(action) {
  try {

    yield put({ type: "COMPLETE_TASK_REQUESTED"})

    const response = yield call([api, api.utility.get], api.urlFor(`/task/setCompleted/${action.payload.taskId}`))

    if (response.status === 200) {
      yield put({
        type: "COMPLETE_TASK_SUCCEEDED",
        payload: { taskId: action.payload.taskId },
      })

      return true

    } else {

      yield put({
        type: "COMPLETE_TASK_FAILED",
        payload: response,
      })

      return false
    }

  } catch (e) {

    yield put({
      type: "COMPLETE_TASK_FAILED",
      payload: e.message
    })


    return false
  }
}

export function* submitTasks(action) {
  try {
    const {
      tasks
    } = action.payload

    yield put({
      type: "SUBMIT_TASKS_REQUESTED"
    })

    const submissions = yield all(tasks.map(t => submitTask({ ...t, taskId: t.id })))

    const noSubmitFailures = submissions.filter(result => result === false).length === 0

    if (noSubmitFailures) {
      yield put({ type: "SUBMIT_TASKS_SUCCEEDED" })

      if (action.onSuccess) {
        yield call(loadTasks) 
        yield call(action.onSuccess)
      }

    } else {
      yield put({ type: "SUBMIT_TASKS_FAILED" })

      yield put({
        type: "SET_ALERT",
        payload: {
          message: "Creating task failed",
          type: "ERROR",
        }
      })

      if (action.onError) {
        yield call(action.onError)
      }
    }
  } catch (e) {
    if (action.onError) {
      yield call(action.onError)
    }

    yield put({
      type: "SUBMIT_TASKS_FAILED",
      payload: e.message,
    })
  } finally {
    if (action.onComplete) {
      yield call(action.onComplete)
    }
  }
}

export default function* taskSaga() {
  yield takeEvery("GET_DEFAULT_TASK_SAGA", getDefaultTask)
  yield takeEvery("TASK_LOAD_SAGA", loadTask)
  yield takeEvery("TASKS_LOAD_SAGA", loadTasks)
  yield takeEvery("SUBMIT_TASKS_SAGA", submitTasks)
}
