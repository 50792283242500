export const colors = {
  black: "#000000",
  brandBlue: "#2a7de1",
  darkGrey: "#404040",
}

export const fonts = {
  roboto: {
    regular: {
      fontFamily: "Roboto, sans-serif"
    },

    medium: {
      fontFamily: "Roboto, sans-serif",
      fontWeight: "500",
    },
  }
}
