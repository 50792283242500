import React, { Component } from "react"
import PropTypes from "prop-types"

import * as taskHelpers from "../../../helpers/taskHelpers"
import withLoadingState from "../../../Elements/Loading/withLoadingState"

import TaskListSection from "./TaskListSection"


class TaskList extends Component {

  renderSections() {
    const { onNavigate, tasks } = this.props

    const tasksByStudy = taskHelpers.groupByStudyId(tasks)

    const studyKeys = Object.keys(tasksByStudy)

    if (studyKeys.length > 0) {
      return (
        studyKeys.map((studyKey, sectionIndex) => {
          return (
            <div
              className="col-12 task_list__column"
              key={sectionIndex}
            >
              <TaskListSection
                onNavigate={onNavigate}
                sectionIndex={sectionIndex}
                tasks={tasksByStudy[studyKey]}
              />
            </div>
          )
        })
      )
    }
    else {
      return (
        <div className="task_list__no_results">
          <h1>No Tasks</h1>
        </div>
      )
    }
  }

  render() {
    return (
      <div className="container task_list__container">
        <div className="row">
          {this.renderSections()}
        </div>
      </div>
    )
  }

} // class TaskList


TaskList.propTypes = {
  onNavigate: PropTypes.func.isRequired,
  tasks: PropTypes.array.isRequired,

  loading: PropTypes.bool.isRequired
}

TaskList.defaultProps = {
  loading: false
}

export default withLoadingState(TaskList)
