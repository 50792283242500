import React, { Component } from "react"
import { connect } from "react-redux"
import Auth from "@aws-amplify/auth"

export class ProfilePassword extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: false,

      formData: {
        password: "",
        newPassword: "",
        confirmPassword: "",
      },
    }
  }

  setField = (name, value) => {
    this.setState({
      formData: {
        ...this.state.formData,
        [name]: value,
      },
    })
  }

  isFormSubmittable = () => {
    const { loading } = this.state
    const { password, newPassword, confirmPassword } = this.state.formData

    return (
      password.length > 0 &&
      newPassword.length > 0 &&
      confirmPassword.length > 0 &&
      newPassword === confirmPassword &&
      !loading
    )
  }

  nothing = async() => {}

  submit = async () => {
    this.props.dispatch({ type: "UPDATE_PASSWORD_SAGA", payload: this.state.formData })
  }

  render() {
    const { formData } = this.state

    return (
      <div className="form-section">
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <h1>Password</h1>
          </div>

          <div className="col-md-6 col-sm-12">
            <div className="form-block">
              <label className="input-label">Current Password</label>

              <input
                type="password"
                value={formData.password}
                onChange={e => {
                  this.setField("password", e.target.value)
                }}
              />
            </div>

            <div className="form-block">
              <label className="input-label">New Password</label>

              <input
                type="password"
                value={formData.newPassword}
                onChange={e => {
                  this.setField("newPassword", e.target.value)
                }}
              />
            </div>

            <div className="form-block">
              <label className="input-label">Confirm Password</label>

              <input
                type="password"
                value={formData.confirmPassword}
                onChange={e => {
                  this.setField("confirmPassword", e.target.value)
                }}
              />
            </div>

            <button
              className={`btn primary-app-btn ${
                this.isFormSubmittable() ? "" : "disabled"
              }`}
              onClick={() => {
                this.isFormSubmittable() ? this.submit() : this.nothing()
              }}
            >
              Update Password
            </button>
          </div>
        </div>
      </div>
    )
  }
}

export default connect()(ProfilePassword)
