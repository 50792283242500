import React, { Component } from "react"
import PropTypes from "prop-types"

class PrimaryButton extends Component {

  buildClassName() {
    const { className } = this.props

    let classNameString = "btn primary-app-btn"

    if (className !== "") { classNameString += ` ${className}` }

    return classNameString
  }

  render() {
    const { isDisabled, onClick, text } = this.props

    return (
      <button
        className={this.buildClassName()}
        disabled={isDisabled}
        onClick={onClick}
      >
        {text}
      </button>
    )
  }

} // class PrimaryButton

PrimaryButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,

  className: PropTypes.string,
  isDisabled: PropTypes.bool
}

PrimaryButton.defaultProps = {
  className: "",
  isDisabled: false
}

export default PrimaryButton
