const initialState = {
  all: [],
}

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action

  switch (type) {
  case "GET_NOTIFICATIONS_SUCCEEDED":
    return {
      ...state,
      all: payload,
    }
  case "REMOVE_NOTIFICATION_SUCCEEDED":
    return {
      ...state,
      all: state.all.filter(n => n.id != payload.notificationId),
    }
  default:
    return state
  }
}
