import React from "react"
import PropTypes from "prop-types"

import AudioButton from "../../../Elements/AudioButton/AudioButton"

const PhotoControlActions = props => {
  const { disabled, isVisible, onCaptureClick, onDeleteClick }  = props

  if (isVisible) {
    return (
      <div className="container">
        <div className="video-control row">
          <AudioButton
            src={require("../../../assets/images/camera.png")}
            onClick={onCaptureClick}
            isDisabled={disabled.includes("camera")}
          />

          <AudioButton
            src={require("../../../assets/images/delete.png")}
            onClick={onDeleteClick}
            isDisabled={disabled.includes("trash")}
          />
        </div>
      </div>
    )
  }

  return null
}

PhotoControlActions.propTypes = {
  onCaptureClick: PropTypes.func.isRequired,
  isVisible: PropTypes.bool,
}

PhotoControlActions.defaultProps = {
  isVisible: true,
  disabled: [],
}

export default PhotoControlActions
