export const navigationPaths = {
  dashboard: () => "/",
  notifications: () => "/notifications",
  reviewsNew: () => "/reviews/new",
  reviewsSuccess: () => "/reviews/success",
  studies: () => "/studies",
  studiesShow: (id) => `/studies/${id}`,
  tasks: () => "/tasks",
  tasksComplete: (id) => `/tasks/complete/${id}`,
  tasksShow: (id) => `/tasks/${id}`,
  tasksSuccess: () => "/tasks/success",
  usersProfile: () => "/users/profile",
  error: () => "/error",
}

export const routerPaths = {
  dashboard: "/",
  notifications: "/notifications",
  reviewsNew: "/reviews/new",
  reviewsSuccess: "/reviews/success",
  studies: "/studies",
  studiesShow: "/studies/:id",
  tasks: "/tasks",
  tasksComplete: "/tasks/complete/:id",
  tasksShow: "/tasks/:id",
  tasksSuccess: "/tasks/success",
  usersProfile: "/users/profile",
  error: "/error",
}
