import React, { Component } from "react"
import PropTypes from "prop-types"

import { Link } from "react-router-dom"


class SuccessDisplay extends Component {

  render() {
    const { buttonText, headingText, navigationPath, subText } = this.props

    return (
      <div className="success_display__wrapper">
        <div className="container success_display__container">
          <div className="row success_display__icon_row">
            <div className="col-sm-12">
              <img
                alt=""
                className="success_display__icon"
                src={require("../../../assets/images/white-check.png")}
              />
            </div>
          </div>

          <div className="row success_display__heading_text_row">
            <div className="col-sm-12">
              {headingText}
            </div>
          </div>

          <div className="row success_display__sub_text_row">
            <div className="col-sm-12">
              {subText}
            </div>
          </div>

          <div className="row success_display__button_row">
            <div className="col-sm-12">
              <Link
                className="btn primary-app-btn success_display__button"
                to={{
                  pathname: navigationPath,
                  state: {}
                }}
              >
                {buttonText}
              </Link>
            </div>
          </div>
        </div>
      </div>
    )
  }

} // class SuccessDisplay


SuccessDisplay.propTypes = {
  navigationPath: PropTypes.string.isRequired,

  buttonText: PropTypes.string,
  headingText: PropTypes.string,
  subText: PropTypes.string
}

SuccessDisplay.defaultProps = {
  buttonText: "Done",
  headingText: "Your submission has been shared!",
  subText: "Thanks for sharing your experience!"
}

export default SuccessDisplay
