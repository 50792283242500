import React from "react"
import PropTypes from "prop-types"

const CheckBoxList = props => {
  const colClass = `col-md-${props.columnSize}`

  return (
    <div className="row">
      {props.options.map((option, index) => {
        return(
          <div className={`check-box ${colClass}`} key={index}>
            <label className="check-box__label" htmlFor={`check_${option.id}`}>
              {option.name}

              <input
                className="check-box__check"
                id={`check_${option.id}`}
                name={option}
                type="checkbox"
                checked={option.isChecked}
                onChange={() => { props.onChange(option) } }
              />

              <span className="checkmark"></span>
            </label>
          </div>
        )
      })}
    </div>
  )
}

CheckBoxList.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    isChecked: PropTypes.bool.isRequied,
    name: PropTypes.string.isRequired,
  })).isRequired,
  onChange: PropTypes.func.isRequired,
  columnSize: PropTypes.number,
}

CheckBoxList.defaultProps = {
  columnSize: 6,
}

export default CheckBoxList
