export function isSafari() {
  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent) || isMobileSafari()
}

export function isMobileSafari() {
  var iOS = !!navigator.userAgent.match(/iPad/i) || !!navigator.userAgent.match(/iPhone/i)
  var webkit = !!navigator.userAgent.match(/WebKit/i)
  return iOS && webkit && !navigator.userAgent.match(/CriOS/i)
}

export function isChrome() {
  const isChromium = window.chrome;
  const winNav = window.navigator;
  const vendorName = winNav.vendor;
  const isOpera = typeof window.opr !== "undefined";
  const isIEedge = winNav.userAgent.indexOf("Edge") > -1;
  const isIOSChrome = winNav.userAgent.match("CriOS");

  if (isIOSChrome) {
    return true
    // is Google Chrome on IOS
  } else if(
    isChromium !== null &&
    typeof isChromium !== "undefined" &&
    vendorName === "Google Inc." &&
    isOpera === false &&
    isIEedge === false
  ) {
    return true
  } else {
    return false
  }
}
