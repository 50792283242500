export function filterByCompleted(tasks) {
  return tasks.filter((task) => task.completedDate !== null)
}

export function findByStudyId(tasks, studyId) {
  // WARNING: studyId in the current API is not an integer. It is a special string identifier.

  return tasks.filter((task) => task.studyId === studyId)
}

export function groupByStudyId(tasks) {
  // WARNING: studyId in the current API is not an integer. It is a special string identifier.

  let tasksById = {}

  tasks.forEach((task) => {
    tasksById[task.studyId] = tasksById[task.studyId] || []
    tasksById[task.studyId].push(task)
  })

  return tasksById
}
